<template>

    <div>

<v-dialog  id="tip-moderator" hide-footer centered>
<v-row>
    <v-col md="12">
                                <h3>{{ moderator.moderator_name }}</h3>
                                <p  class="my-4 text-sm text-primary"> {{ moderator.moderator_sub_title }} </p>
                                </v-col>
<v-col>
  <paypal-view v-if="is_signed === 'true'"></paypal-view>
  <v-row>
    <v-col cols="6">
     
      <div  v-if="is_signed === 'false'">
        <p style="font-weight: 555;">resiter to continue donation</p>
        <v-img fluid  style="cursor: pointer;"  @click="goTo" src="https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1619585229718.png" ></v-img>
          
    </div>
    </v-col>
  </v-row>
</v-col>

</v-row>



</v-dialog>
<v-dialog  id="rate-moderator" hide-footer centered>
<v-row>
    <v-col md="12">
                                <h3>{{ moderator.moderator_name }}</h3>
                                <p  class="my-4 text-sm text-primary"> {{ moderator.moderator_sub_title }} </p>
                                </v-col>
                              
                                


                               
<v-col>
  <v-col md="3">
rate 1:
  </v-col>
  <v-col md="8">


    <range-slider
    class="slider"
    min="1"
    max="100"
    step="1"
    v-model="rates.rate_1" 
    style="width:100%;">
    
  </range-slider>

  </v-col>
  
  <v-row>
    <v-col cols="6">
     
      <div  v-if="is_signed === 'false'">
        <p style="font-weight: 555;">resiter to continue rating</p>
        <v-img fluid  style="cursor: pointer;"  @click="goTo" src="https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1619585229718.png" ></v-img>
          
    </div>
    </v-col>
  </v-row>
</v-col>

</v-row>



</v-dialog>
    <v-card
  
                text-variant="primary"
                title-variant="primary"
                style="max-height: 7.2rem; min-height: 7.2rem; border-radius: 3px;"
                
                  id="moderator"
                 class=" m-0 my-2 p-0  d-flex moderator card-menu-dark"   >
                 <v-row  align-v="center" class="d-flex" no-gutters>
                  <v-col cols="4" class="text-xs m-auto">
                    <v-avatar style=" box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"  size="3.6rem"> <v-img :src="moderator.moderator_img_src"></v-img></v-avatar>
  
                    
  
  
                                    </v-col>
                    <v-col cols="8" align-v="center" class="text-xs my-auto ">
                   <p  class="text-bold text-base mt-2" >{{ moderator.moderator_name }}
                                      <br>
                                                                          <span  class="candidate-text text-sm">{{ moderator.moderator_sub_title }}</span>  
                                                                      </p>
                                     
                                      </v-col>
  
  
                 </v-row>
 
    </v-card>
   
    <v-progress  height="15px" variant="info" show-value max=1000  :value="moderator_total_donation"  class="mv-1"></v-progress>
    <v-progress  height="15px" variant="success" show-value   class="mv-1"></v-progress>
    
</div>
  </template>
  <script>
  import PaypalView from '../../WalletModerator/PaypalView'
  import store from '@/store'
  import RangeSlider from 'vue-range-slider'
// you probably need to import built-in style

  export default {
      name: 'debate-moderator',
      components: {
        PaypalView,
        RangeSlider
      },
      props: {
        moderator: Object,
      },
      data() {
        return {
          rates:{
            rate_1: 0,
            rate_2: 0,
            rate_3: 0,
            rate_4: 0,
            rate_5: 0
          },
        }
        },
        computed:{
          moderator_total_donation(){
         return store.state.debates.active_debate.donations.moderator
       } ,
          showModalModeratorDonate(){
            return store.state.ledger_auth.moderator.donation
          },
          showModalModeratorRate: {
      // getter
      get: function () {
       
        return store.state.ledger_auth.moderator.rate
      },
      // setter
      set: function (newValue) {
        this.showModalModeratorRate = newValue 
      }
    },
          is_signed(){
                                                return this.$cookies.get('is_signed_in')
                                              },
          
        },
        methods: {
          goTo(){
                window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=https%3A%2F%2Fwww.axb.ardkon.com%2Fauth&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress+w_member_social&client_id=78dmbk81tskav0"
                console.log('hii')
            },
        showModal() {
          this.$root.$emit('bv::show::modal', 'tip-moderator', '#btnShow')
           }, showRate() {
            
            this.$root.$emit('bv::show::modal', 'rate-moderator', '#btnShow')

           },
        }
  
    }
  </script>
  <style>
    .button-radius{
        border-radius: 9px!important;
    }
  .activity-card{
    max-height: 21vh;
    max-height: 21vh;
  }
  .moderator-img{
   max-height: 12vh;
    max-width: 12vw;
  }
  
  .text-bold{
    font-weight:600;
  
  }
  .moderator-text{
    max-height: 2.1rem; overflow: hidden;
  }
  .moderator{
  border:0
  }
  .moderator .card-img-left {
               width: 100%;
               height: 100%;
               max-height: 7.2rem;
               min-height: 7.2rem;
               max-width: 6rem;
               min-width: 6rem;
               object-fit: cover;
               border-top-left-radius: 3px;
               border-bottom-left-radius: 3px;
               border: 0;
           }
  .card-menu-dark{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(0, 0, 0, 0.63);
    max-width: 20 vw;
  
  }
  .card-menu-white{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(255, 255, 255, 0.63);
    max-width: 20 vw;
    border-radius: 18px!important;
  }
  @media only screen and (max-width: 420px) {
    .story-card .card-img-left {
      width: 100%;
      height: 100%;
      max-height: 15rem;
      min-height: 12rem;
      max-width: 6.3rem;
      min-width: 6.3rem;
      object-fit: cover;
  }
  .story-card-text{
    max-height: 6.3rem; min-height: 6.3rem; overflow:hidden
  }
  }
  </style>
  