<template>
    <div>
        <v-card>
            <v-card-text>
                <v-form>
                    <h3>debate details</h3>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="debate title" hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                name="debate_small_descritption"
                                label="debate subtitle"
                                value
                                auto-grow
                                rows="3"
                                hint="Hint text"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="6">
                            <v-row justify="space-around">
                                <v-time-picker v-model="e4" color="green lighten-1"></v-time-picker>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="first topic " hide-details="auto"></v-text-field>
                            <v-textarea
                                name="first_topic_small_description"
                                label="first topic small description"
                                value
                                auto-grow
                                rows="1"
                                hint="Hint text"
                            ></v-textarea>
                            <v-text-field label="second topic " hide-details="auto"></v-text-field>
                            <v-textarea
                                name="second_topic_small_description"
                                label="second topic small description"
                                value
                                auto-grow
                                rows="1"
                                hint="Hint text"
                            ></v-textarea>
                            <v-text-field label="third topic " hide-details="auto"></v-text-field>
                            <v-textarea
                                name="third_topic_small_description"
                                label="third topic small description"
                                value
                                auto-grow
                                rows="1"
                                hint="Hint text"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="6">
                            <v-select multiple chips label="moderators of choice" v-model="language" :items="languages_select"       item-text="language_name"
></v-select>

                        </v-col><v-col cols="6">
                            <v-select multiple chips label="select your condidates" v-model="language" :items="languages_select"></v-select>

                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: 'request-debate-start',
    data(){
        return {
            languages_select:[{language_name: 'hii', language_url : ''}]
        }
    },
    components: {}
}
</script>