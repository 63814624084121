<template>

    <div>
      <div class="hidden-row"></div>
      <div class="hidden-row"></div>
        <v-row>
          <v-col cols="12" sm="2">

          </v-col>
          <v-col cols="12" sm="8">
            <v-card
       
        
                
            id="topic"
           class=" mx-5   p-0  d-flex topic card-menu-white " 
            >
            <v-card-text>
              <v-row     class="h-100">
               
               <v-col sm="12"   class=" shaddow-text " style="position:relative;vertical-align: middle;top:25%;" >
               
               <v-text-field type="text" label="username" v-model="form.username" placeholder="username"></v-text-field>
             
      <v-text-field type="password" label="password" v-model="form.password" placeholder="Password"></v-text-field>
               
   
                              
                                 </v-col>
                                <v-col sm="4"></v-col>
 <v-col sm="4">
  <v-btn variant="info" @click="login" block>login</v-btn>
 </v-col>
            </v-row>
            </v-card-text>
            
            </v-card>
          </v-col>
        </v-row>
        
 
  

</div>
  </template>
  <script>
  import store from "@/store";
  export default {
      name: 'moderator-sign-in',
      components: {
  
      },
      props: {
        
      },
      data() {
        return {
            form: {
              username: '',
              password: '',
              debate_name: this.$route.params.room_id
            }
        
        }
      },
        methods: {
        
          login(){
            console.log(this.form)
            store.dispatch('debates/moderatorLogin', this.form)
          }
        }
  
    }
  </script>
  <style >
  
    .card-menu-white{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(255, 255, 255, 0.63);
    max-width: 20 vw;
    border-radius: 18px!important;
  
  }
  </style>
  