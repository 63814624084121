<template>
  <div>
    

  
    <v-card
      color="rgba(255,255,255,0.63)"
      text-variant="primary"
      title-variant="primary"
      border-variant="primary"
      style="max-height: 5.4rem; min-height:5.4rem;min-width: 12rem;"
      class="m-0 my-2 p-0 d-flex card-menu-dark-initiative"
    >
    
      
        <v-row align-v="center" class="d-flex" no-gutters>
          <v-col cols="4" class="text-xs " style="background:white">
          
              <v-img contain height="5.4rem" width="5.4rem"  :src="debate_initiative.debate_date_time"></v-img>
          </v-col>
          <v-col cols="8" class="">
            <v-card-text> <p class="caption font-weight-bold white--text">
           <span class="white--text"> {{debate_initiative.debate_sub_title}} </span>  
              <br />
              <span class="candidate-text text-sm"></span>
              
              <v-btn
                  v-show="!hidden"
                  color="outline-primary"
                  outlined
                  dark
                  absolute
                  bottom
                  right
                  x-small
                  :href="debate_initiative.debate_status"
                  target="_blank"
                 class="text-lowercase"
                >
view more                </v-btn>
            
          </p></v-card-text>
           
          </v-col>
        </v-row>
    
    </v-card>
   
    
 
  </div>
</template>
  <script>
import store from '@/store'
export default {
  name: 'debate-candidate',
  components: {
  },
  props: {
    candidate: Object,
    getMax: Number

  },
  data() {
    return {
      dialogDonation: false,
      ratingCandidate: false,
      rates: {
        rate_1: 0,
        rate_2: 0,
        rate_3: 0,
        rate_4: 0,
        rate_5: 0,
        
      }
    }
  },
  computed: {

    total_rate(){
      return (this.rates.rate_1 +  this.rates.rate_2 +  this.rates.rate_3 +  this.rates.rate_4 +  this.rates.rate_5) / 5
    },
    candidate_A_total_donation() {
      return store.state.debates.active_debate.donations.candidate_A
    }, debate_initiative() {
      return store.state.debates.active_debate.general
    },
    candidateAModalDonation() {
      return store.state.ledger_auth.candidate.candodate_a_donation
    }, candidateBModalDonation() {
      return store.state.ledger_auth.candidate.candodate_b_donation
    },
    is_signed() {
      return this.$cookies.get('is_signed_in')
    },

  },
  methods: {
    goTo() {
      window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=https%3A%2F%2Fwww.axb.ardkon.com%2Fauth&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress+w_member_social&client_id=78dmbk81tskav0"
      console.log('hii')
    },
    setCandidateActive() {
      store.dispatch('debates/setActiveCandidate', this.candidate)
      this.$root.$emit('bv::show::modal', this.candidate.candidate_id, '#btnShow')


    },
    showModal() {
      this.$root.$emit('bv::show::modal', this.candidate.action_user.action_user_id, '#btnShow')
    },
  }

}
</script>
  <style>
.button-radius {
  border-radius: 9px !important;
}
.activity-card {
  max-height: 21vh;
  max-height: 21vh;
}
.candidate-img {
  max-height: 12vh;
  max-width: 12vw;
}

.text-bold {
  font-weight: 600;
}
.candidate-text {
  max-height: 2.1rem;
  overflow: hidden;
}
.candidate {
  border: 0;
}
.candidate .card-img-left {
  width: 100%;
  height: 100%;
  max-height: 7.2rem;
  min-height: 7.2rem;
  max-width: 6rem;
  min-width: 6rem;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 0;
}
.card-menu-dark-initiative {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(0, 0, 0, 0.63)!important;
  max-width: 20 vw;
  border-radius: 3px !important;
}
.card-menu-darker {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(40, 44, 51, 0.81);
  max-width: 20 vw;
  border-radius: 3px !important;
}
.card-menu-white {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.63);
  border-radius: 3px !important;
}
@media only screen and (max-width: 420px) {
  .story-card .card-img-left {
    width: 100%;
    height: 100%;
    max-height: 15rem;
    min-height: 12rem;
    max-width: 6.3rem;
    min-width: 6.3rem;
    object-fit: cover;
  }
  .story-card-text {
    max-height: 6.3rem;
    min-height: 6.3rem;
    overflow: hidden;
  }
}
.my-checkbox .v-label {
  font-size: 9px;
  line-height:12px!important;
} 

::v-deep .my-checkbox .v-label {
 font-size: 9px;
 line-height:12px!important;
}
</style>
  