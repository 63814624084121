<template>
  <div class="main-debate">
    <v-container fluid fill-height>
     
      
      <v-row align="center" justify="center">
      <v-col>
       

        <moderator-sign-in v-if="sharedState.ledger_auth.moderator_debate.sign_in === true"></moderator-sign-in>
        <moderator-tool-box v-if="sharedState.ledger_auth.moderator_debate.debate_room === true"></moderator-tool-box>
      </v-col>
      </v-row>
    </v-container>
    
  </div>
</template>
<script>
// Charts

// Components
import ModeratorSignIn from './axb/Moderator/ModeratorSignIn.vue'
import store from '@/store'
import ModeratorToolBox from './axb/Moderator/ModeratorToolBox.vue'

export default {
  components: {
    ModeratorSignIn,
    ModeratorToolBox
  },
  store,

  data() {
    return {

      param: this.$route.params,
      sharedState: store.state
      
    }
  },
methods:{

},

   async created() {

    // Need if statements depending on the sections that are existing this way we would not have errors











  },
  computed: {

   
    action() {

      return store.getters['getAction/getActiveAction'](this.param)

    },
    action_general() {
      return store.state.getAction.active_action.action_general
    },

  },







}
</script>
<style scoped>
.hidden-row-3 {
  position: relative;
  height: 54px !important;
  width: 100%;
}
.hidden-row-5 {
  height: 15px !important;
}
.hidden-row-5 .action {
  height: 12px !important;
}
.hidden-row-4 {
  position: relative;
  height: 45px !important;
  width: 100%;
}
.text-bold {
  font-weight: 600;
}
.right-sidebar {
  right: 0;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-pills {
}

@media only screen and (max-width: 981px) {
  .right-sidebar {
    position: relative;
    height: auto;
  }
}

.main-debate {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  background-color: #F9F9F9;
  overflow-x: hidden;
  overflow-y: scroll;
}

</style>
