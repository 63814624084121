<template>
<div  >

<v-row>

  <v-col cols="12">
    <v-dialog v-model="pannel_auth" max-width="600">
      <choose-region ></choose-region>

    </v-dialog>

    <h4>speakers</h4>
  <hr>
<debate-candidates></debate-candidates>
  </v-col>
  <v-col class="my-auto mb-1"  cols="12">
    <span class="text-center caption font-weight-bold">suggest a question</span>
   <v-btn block large color="primary" @click="showQuestions" >  
  <v-icon class="white--text text-lowercase" >mdi-chat-question</v-icon></v-btn>
 </v-col>
<v-col class="mt-0 py-0" md="12">
  <h4>guests</h4>
  <hr>
</v-col>
<v-col style="max-height:5.4rem; overflow-x: hidden; overflow-y: scroll" cols="8">

  <span class="caption primarry--text mx-2 font-weight-bold" v-for="user in users" :key="user.first_name"> {{user.first_name}} {{user.last_name}}, </span>

</v-col>

</v-row>
<v-row>

</v-row>
</div>
</template>
<script>

  import DebateCandidates from './DebateCandidates/DebateCandidates'
  import ChooseRegion  from './SignUp/ChooseRegion.vue'

import store from '@/store'
export default {
    name: 'debate-view-side-bar',
    components: {
      DebateCandidates,
      ChooseRegion
    },


    data () {
      return {
sharedState: store.state,


      }
    },
    computed:{
      users(){
        return store.state.debates.active_debate.guests
      },
      pannel_auth(){
        return store.state.ledger_auth.pannel_sign_up
      },
      debate_general(){
     var general = store.state.debates.active_debate.general
     general.candidate_a = this.debate_Candidate_A
     general.candidate_b = this.debate_Candidate_B
     return general
   },
   moderator(){
         return store.state.debates.active_debate.moderator
       }
    },
    methods: {
     
      showQuestions(){
        store.dispatch('ledger_auth/showQuestionsSection')
      },
     donate(){
          if(this.sharedState.ledger.donate_section === false){
                                                                                this.$store.dispatch('ledger/setGalleryDonationDone')

                                                                                                                                                    this.$store.dispatch('ledger/setDonation')

              } else {


                                                                                 this.$store.dispatch('ledger/setDonationDone')

              }










                 },
    }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.hidden-row-side{
  height:9vh
}


@media (max-width: 978px) {
  .hidden-row-side{
  height:0vh
}
}
</style>
