<template>

   

 
        
        <v-card
        border-variant="primary"
        
               style="height:13.5vh"
        id="topic"
       class=" m-0   p-0  d-flex topic card-menu-transparent " 
        >
        <div class="h-100 card-menu-variant" style="width:50%;">
          <v-row   >
          
          <v-col  class="white--text mt-2 text-center">
             <p><span  >A</span> 

              <br>
              <span class=" font-weight-bold" style="font-size:18px;">50%</span></p> 
              
          </v-col></v-row>
        </div>
        <v-row>
                  <v-col class=" mt-2 text-center">
              <p class="text-primary text-center"> B<br>
              <span class=" font-weight-bold" style="font-size:18px;">50%</span></p>
          </v-col>
         </v-row>
         <v-card-actions>
      <v-row  justify="end">
        <span class="subheading mr-2">
          <v-img
            id="imgShow"
            @click="info"
            width="18px"
            src="https://cdn.discordapp.com/attachments/835173834855284787/836894701231407174/info-1.png"
          ></v-img>
        </span>
      </v-row>
    </v-card-actions>
    <v-dialog v-model="topic_created" width="360">
      <v-card>
        <v-card-title>{{ topic.topic_title }}</v-card-title>
        <v-card-subtitle>{{ topic.topic_small_description }}</v-card-subtitle>
        <v-card-text>{{ topic.topic_description }}</v-card-text>
      </v-card>
    </v-dialog>
        </v-card>



</template>
<script>

export default {
  name: 'debate-topic-done',
  components: {

  },
  props: {
    topic: Object,
  },
  data() {
    return {
        style1:{
            width: '54%',
        },
        topic_created:false
    }
    },
    methods: {
      info() {
      this.topic_created = true;
    },
      showTopic() {
    this.$refs[this.topic.topic_id].show()
  },
    showModal() {
         this.$root.$emit('bv::show::modal', this.topic.topic_id, '#imgShow')
       },
    }

}
</script>
<style >
.activity-card{
max-height: 21vh;
max-height: 21vh;
}
.topic-img{
max-height: 12vh;
max-width: 12vw;
}

.text-bold{
font-weight:600;

}
.topic-text{
max-height: 2.1rem; overflow: hidden;
}
.topic{
border:0
}
.topic .card-img-left {
           width: 100%;
           height: 100%;
           max-height: 7.2rem;
           min-height: 7.2rem;
           max-width: 6rem;
           min-width: 6rem;
           object-fit: cover;
           border-top-left-radius: 3px;
           border-bottom-left-radius: 3px;
       }
.card-menu-dark{
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
background-color:rgba(0, 0, 0, 0.63);
max-width: 20 vw;
border-radius: 3px!important;

}
.voted{
border-style: solid;
border: 9px!important;
border-color: #132F3E;
}
.card-menu-variant{
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
background-image:linear-gradient(to right, #132F3E , #1A4B35);
max-width: 20 vw;
border-radius: 3px!important;

}
.card-menu-transparent{
background-color: transparent;
/* max-width: 9vw; */
border-radius: 3px!important;
}

@media only screen and (max-width: 420px) {
.story-card .card-img-left {
  width: 100%;
  height: 100%;
  max-height: 15rem;
  min-height: 12rem;
  max-width: 6.3rem;
  min-width: 6.3rem;
  object-fit: cover;
}
.story-card-text{
max-height: 6.3rem; min-height: 6.3rem; overflow:hidden
}
}
</style>
