<template>
  <div>
    <v-card class="py-4 m-4  topic text-center card-menu-white" style="max-width: 630px;">
      <v-card-title>
       <h3>take a seat as:</h3> 
      </v-card-title>
      <v-card-text>
        <v-row class="py-4">
          <v-col sm="6" cols="12" v-for="n_type in types" :key="n_type.name">
            <v-card
              border-variant="primary"
              class="d-flex topic m-1 card-menu-white"
              @click="goDark(n_type)"
              :class="{
                'card-menu-white-types': n_type.darkMode,
                'card-menu-dark-types': !n_type.darkMode,
              }"
            >
              <v-card-title>
              

              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-img class="py-3 my-3" fluid :src="n_type.img_url"></v-img>
                  </v-col>
                  <v-col cols="8" class="my-auto" >  <h3
                  class="py-2 pt-4 m-1 info--text"
                  :class="{
                    'dark-type-card-text': n_type.darkMode,
                    'white-type-card-text': !n_type.darkMode,
                  }"
                >{{ n_type.name }}</h3></v-col>
                 
                </v-row>
               
              </v-card-text>
            </v-card>
          </v-col>
          
       
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
  <script>
import store from "@/store";
import undraw_interview_rmcf from '@/assets/undraw_interview_rmcf.svg'
import undraw_candidate_ubwv from '@/assets/undraw_candidate_ubwv.svg'
export default {
  name: 'apply-candidate',
  components: {

  },
  props: {

  },
  data() {
    return {
      form: {
      
        debate_name: this.$route.params.room_id,
        types: []
      },
      types: [{
        name: 'moderator',
        darkMode: true,
        selected: false,
        img_url: undraw_interview_rmcf
      },
      {
        name: 'candidate',
        darkMode: true,
        selected: false,
        img_url: undraw_candidate_ubwv
      },
      ],

    }
  },
  methods: {

    goDark(n_type) {
     
        this.form.types.unshift(n_type.name)
        //store.dispatch('debates/candidateApplyType', this.form)
        if(n_type.name ==='candidate'){
          store.dispatch('ledger_auth/setApplyCandidate')

        }else{
          store.dispatch('ledger_auth/setApplyModerator')

        }
      


    },

    next(b_type) {

     
          this.form.types.unshift(b_type.name)
       

    },
    login() {
      store.dispatch('debates/moderatorLogin', this.form)
    }
  }

}
</script>
  <style >
.card-menu-white {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.63);
  max-width: 20 vw;
  border-radius: 3px !important;
}
.type-card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.11);
  background-color: rgba(255, 255, 255, 0.63) !important;
  height: 12vh;
  cursor: pointer;
}

.type-card:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.11);
  background-color: #40454c !important;
  color: white !important;
  cursor: pointer;
}
.type-card-text {
  height: 12vh;
  cursor: pointer;
}
.type-card-text:hover {
  color: white !important;
  cursor: pointer;
}
.white-type-card-text {
  color: white !important;
  cursor: pointer;
}


.card-menu-dark-types {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(19, 47, 62, 0.63) !important;
  max-width: 20 vw;
  border-radius: 18px !important;
  color: white;
  border-width: 3px;
}

.card-menu-white-types {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.63);
  max-width: 20 vw;
  border-radius: 3px !important;
  color: white;
}
.card-menu-white-types:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(19, 47, 62, 0.63);
  max-width: 20 vw;
  border-radius: 3px !important;
  color: white;
}
</style>
  