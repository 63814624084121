<template>
<div style="position:relative; top:6px;" class="px-4" >




<v-row >
<v-col md="12" >
<p class="text-primary text-lg my-1"> <span class="font-weight-bold text-xl"><strong>{{ debate_general.debate_title}}: </strong></span> <span  v-html="debate_general.debate_sub_title"> </span> </p>
<p  class="light text-base text-primary ">
                         <span v-html="debate_general.debate_date_time"> </span></p>





  
</v-col>

<v-col cols="12" sm="8" class="my-0 py-0">
      
      <v-responsive  style="border-radius: 18px;" :aspect-ratio="16/9"> <iframe width="100%" height="100%" style="border-radius: 18px;" :src="debate_general.debate_video_url"></iframe></v-responsive>
       
     
    </v-col>
<v-col class="m-0" md="4">
  <debate-topics></debate-topics>
</v-col>
</v-row>









            </div>
</template>
<script>

   import store from '@/store'
   import DebateTopics from './DebateTopics/DebateTopics'

export default {
    name: 'debate-general-main',
    components: {
      DebateTopics
    },

     props: {
          debate_general: Object,
        },
    data() {
      return {
          sharedState: store.state,

      }
    },
    methods:{
     
    },

    computed: {
    
    }


  }
</script>
<style>
.light{
font-weight: 400
}
.stream{

  width: 100%;
  height: 63vh;
}
@media only screen and (max-width: 810px) {

.stream{

  width: 100%;
  height: 51vh;
}
}
@media only screen and (max-width: 666px) {

.stream{

  width: 100%;
  height: 45vh;
}
}
@media only screen and (max-width: 555px) {

.stream{

  width: 100%;
  height: 42vh;
}
}
@media only screen and (max-width: 444px) {

.stream{

  width: 100%;
  height: 33vh;
}
}
@media only screen and (max-width: 390px) {

.stream{

  width: 100%;
  height: 30vh;
}
}
.main-action-hr{
  margin:6px!important;
  padding:0px;
}
</style>
