<template>
  <div class="main-debate">
    <v-container  fluid >
    
      

      <v-row class="d-flex">
        <v-col class="text-left" md="2" cold="12">
          <v-img
            contain
            height="72"
            style="display:block; cursor: pointer; z-index:202;"
            src="https://media.discordapp.net/attachments/835173834855284787/835478398260346890/logoled-1.png?width=648&height=463"
            class="logo-axb-1 text-right"
          ></v-img>
        </v-col>
       <v-col cols="10"
       ></v-col>    
        <v-col
          cols="12"
          md="8"
        >
          <div>
          
            <debate-view></debate-view>
          </div>
        </v-col>

      

        

        

        <v-col
         
          cols="12"
          class=""
          md="4"
        >
          <debate-view-side-bar ></debate-view-side-bar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// Charts

// Components

import axios from "axios";

import DebateView from './axb/Debate/DebateView.vue';
import DebateViewSideBar from './axb/Debate/DebateViewSideBar.vue';

import store from '../store'

export default {
  components: {
   
  
   
    DebateView,
    DebateViewSideBar
  },
  store,

  data() {
    return {

      message_error: '',
      success_error: '',
      sharedState: store.state,
      is_signed_in: true,
      param: this.$route.params.debate_param,
      tab: null,
        items: [
          'Stage 1', 'Stage 2', 'Stage 3',
        ],

    }
  },
methods:{

 async login(){
    var href;
      await axios.post('https://apiss.ardkon.com/linked_in?basics=axb', {}, {
                                           }).then((res) => {
                                             console.log(res)
                                            href = res.data.authorization_uri
                                            console.log(href)
                                             this.authorize(href)
                                           })
  },

  async authorize(href){
    axios.get(href).then((res1) => {
                                             console.log(res1)
                                            
                                           })
  }
},

   async created() {

    // Need if statements depending on the sections that are existing this way we would not have errors
    await store.dispatch('debates/rmDebates')

    await store.dispatch('debates/getDebates')



    await store.dispatch('debates/setDebateActiveDebate', this.debate)

    await store.dispatch('debates/getMainInfo', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/getTopics', { debate_id: this.debate.debate_id })

    await store.dispatch('debates/getModerator', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/GetChatMessages', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/GetQuestions', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/getCandidates', { debate_id: this.debate.debate_id })













  },
  computed: {

    moderator(){
      return store.state.debates.active_debate.moderator
    }, 
   
    debate() {

      return store.getters['debates/getActiveDebate'](this.param)

    },
    action_general() {
      return store.state.getAction.active_action.action_general
    },

  },







}
</script>
<style scoped>
.hidden-row-3 {
  position: relative;
  height: 54px !important;
  width: 100%;
}
.hidden-row-5 {
  height: 15px !important;
}
.hidden-row-5 .action {
  height: 12px !important;
}
.hidden-row-4 {
  position: relative;
  height: 45px !important;
  width: 100%;
}
.text-bold {
  font-weight: 600;
}
.right-sidebar {
  right: 0;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-pills {
}

@media only screen and (max-width: 981px) {
  .right-sidebar {
    position: relative;
    height: auto;
  }
}

.main-debate {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  background-color: #F9F9F9;
  overflow-x: hidden;
  overflow-y: scroll;
}

</style>
