<template>
  <div class="mb-0 pb-0 px-4">
    <v-row class>
      <v-col cols="12" sm="8" class="py-2 order-sm-first order-md-1">
        <v-responsive style="border-radius: 6px;" :aspect-ratio="16 / 9">
          <iframe
            width="100%"
            height="100%"
            style="border-radius: 6px;"
            :src="debate_general.debate_video_url"
          ></iframe>
        </v-responsive>

        <v-col md="12" class="px-0">
          <v-row>
            <v-col cols="6">
              <debate-comments></debate-comments>
            </v-col>
            <v-col cols="6">
              <debate-comments-cons></debate-comments-cons>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mx-0 px-0 mt-3" cols="12">
          <v-card class="mx-0 py-0 card-menu-darken">
            <v-row class="py-0">
              <v-col class="py-0" cols="9">
                <v-card-text class="font-weight-bold white--text">{{ debate_general.debate_title }}</v-card-text>
              </v-col>
              <v-col cols="3" class="my-auto">
                <v-btn small outlined @click="vote_click = true" color="white">vote</v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-dialog max-width="600" v-model="vote_click">
            <v-card color="primary">
              <v-card-title class="white--text font-weight-bold" >Statment Vote</v-card-title>
              <v-card-text class="white--text">{{ debate_general.debate_title }}</v-card-text>
              <v-card-text class="white--text">{{ debate.debate_small_description }}</v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      block
                      @click="createStatmentVoteUp"
                      class="white--text"
                      color="success"
                      :disabled="is_signed != 'true'"
                    >vote up 
                    <v-badge :content="voteUp" :value="voteUp" color="primary" >
                       
                      </v-badge></v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      block
                      @click="createStatmentVoteDown"
                      class="white--text"
                      :disabled="is_signed != 'true'"
                      color="red"
                    >
                      vote down 
                      <v-badge :content="voteDown" :value="voteDown" color="primary" >
                       
                      </v-badge>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-card-text>
                      <small
                        v-if="is_signed != 'true'"
                        class="red--text"
                      >please sign up to count the vote</small>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-text
                class="caption white--text"
              >*note: Your vote can only be casted once. It will determine the general opinion of all the guests of this stage after the panelist discussing and elaborating on the statement:{{ debate_general.debate_title }}</v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-col>
      <v-col md="4" cols="12" class="my-0 py-0 order-sm-1 order-md-0">
        <debate-initiative></debate-initiative>
        <br />
        <h4>moderator</h4>
        <hr />

        <debate-moderator :moderator="debate.moderator"></debate-moderator>
        <br />
        <h4>topics</h4>
        <hr />

        <DebateTopics></DebateTopics>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DebateTopics from './DebateTopics/DebateTopics'
import DebateInitiative from './DebateInitiative/DebateInitiative'
import DebateComments from './DebateCommentsPros/DebateComments'
import DebateCommentsCons from './DebateCommentsCons/DebateCommentsCons'
import DebateModerator from './DebateModerator/DebateModerator'
import store from '@/store'

export default {
  name: 'debate-general-main',
  components: {
    DebateTopics,
    DebateInitiative,
    DebateComments,
    DebateModerator,
    DebateCommentsCons
  },

  props: {
    debate_general: Object,
  },
  data() {
    return {

      sharedState: store.state,
      vote_click: false

    }
  },
  methods: {
    createStatmentVoteUp() {
      store.dispatch('debates/createStatmentVote', { vote_details: 'up', debate_id: this.debate.debate_id })
    }, createStatmentVoteDown() {
      store.dispatch('debates/createStatmentVote', { vote_details: 'down', debate_id: this.debate.debate_id })
    }
  },

  computed: {
    is_signed() {
      return this.$cookies.get('is_signed_in')
    },
    voteUp() {
      return this.debate.votes_count_up.votes_count
    },
    voteDown() {
      return this.debate.votes_count_down.votes_count
    },
    debate() {
      return store.state.debates.active_debate
    }
  }


}
</script>
<style>
.container-2 {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.card-menu-darken {
  background-color: rgba(69, 69, 69, 0.81) !important;
  border-radius: 3px !important;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.light {
  font-weight: 400;
}
.stream {
  width: 100%;
  height: 63vh;
}
@media only screen and (max-width: 810px) {
  .stream {
    width: 100%;
    height: 51vh;
  }
}
@media only screen and (max-width: 666px) {
  .stream {
    width: 100%;
    height: 45vh;
  }
}
@media only screen and (max-width: 555px) {
  .stream {
    width: 100%;
    height: 42vh;
  }
}
@media only screen and (max-width: 444px) {
  .stream {
    width: 100%;
    height: 33vh;
  }
}
@media only screen and (max-width: 390px) {
  .stream {
    width: 100%;
    height: 30vh;
  }
}
.main-action-hr {
  margin: 6px !important;
  padding: 0px;
}
</style>
