<template>
    <div>
      
<v-row class="mt-1">
 
<v-col  class="py-0 my-0"  v-for="candidate in candidates" :key="candidate.candidate_id" cols="12" md="6">

  <debate-candidate  :candidate="candidate"  ></debate-candidate>

 
</v-col>
</v-row>
                        </div>
    </template>
    <script>
    
import store from '@/store'
    
    import DebateCandidate from './DebateCandidate/DebateCandidate.vue'
  
    export default {
        name: 'debate-candidates',
        components: {
          DebateCandidate
         },
        props: {
        },
    
        data() {
          return {
             
            
           sharedState: store.state,
         
    
          }
        },
        computed: {
        
       candidates(){
         return store.state.debates.active_debate.candidates
       }
      , moderator(){
         return store.state.debates.active_debate.moderator
       }
        }
    
    
      }
    </script>
    <style>
    
    </style>
    