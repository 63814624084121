<template>
  <div class="moderator">
   
    <v-dialog v-model="ratingModerator">
      <v-card>
        <v-card-title>
        <h4>{{moderator.moderator_name}}</h4>  
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_1"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_1 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_1"
              ></v-slider>
              <h4 class="info--text text-center"> rate 1</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_2"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_2 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_2"
              ></v-slider>
              <h4 class="info--text text-center"> rate 2</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_3"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_3 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_3"
              ></v-slider>
              <h4 class="info--text text-center"> rate 3</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_4"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_4 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_4"
              ></v-slider>
              <h4 class="info--text text-center"> rate 4</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_5"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_5 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_5"
              ></v-slider>
              <h4 class="info--text text-center"> rate 5</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="total_rate"
                  color="info"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ total_rate }}</span>
                </v-progress-circular>
               <h4 class="info--text text-center"> total rate</h4><br>
               <v-btn color="info" class="text-lowercase button-radius font-weight-bold" @click="ratingModerator = false">submit</v-btn>
            </v-col>
             
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="63vw" hide-footer centered>
      <v-card  tile class="mx-auto">
        <v-card-title>
          <h3>{{ moderator.moderator_name }}</h3>
        </v-card-title>
        <v-card-text>
          <v-row style="overflow:hidden;">
            <v-col cols="12" md="12">
              <p class="my-4 text-primary">{{ moderator.moderator_sub_title }}</p>
            </v-col>
            <v-col class="my-auto py-0" cols="12" md="6" >
             <v-card class="text-lowercase">
              <v-card-title>
  <h4>Terms & Condiditions:</h4>

</v-card-title>
               <v-card-text>
               
              <v-checkbox  size="9px" class="shrink caption size-small"  >

                <template v-slot:label>
    <span >Program R&D</span>
  </template>
              </v-checkbox>
              <v-checkbox class="my-0 py-0 shrink" label="Campaign Actions">                <template v-slot:label>
    <span >Campaign Actions</span>
  </template></v-checkbox>
              <v-checkbox class="my-0 py-0 shrink" label="advertising & Marketing">

                <template v-slot:label>
    <span >advertising & Marketing</span>
  </template>
              </v-checkbox>
              <v-checkbox class="my-0 py-0 shrink" small label="Campaign Roadshow">

                <template v-slot:label>
    <span >Campaign Roadshow</span>
  </template>
              </v-checkbox>
              <br>
              <p class="caption">2.90% + 30&cent; will be included as Paypal fees in the US. <br>
    5.40% + 30&cent; will be included as Paypal fees internationally.
   </p>
               </v-card-text>
             </v-card>
             
            </v-col>
            <v-col class="my-0 py-0" md="6">
              <paypal-view v-if="is_signed === 'true'"></paypal-view>
              <v-row>
                <v-col cols="6">
                  <div v-if="is_signed === 'false'">
                    <p style="font-weight: 555;">resiter to continue donation</p>
                    <v-img
                      fluid
                      style="cursor: pointer;"
                      @click="goTo"
                      src="https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1619585229718.png"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6"></v-col>
            <v-col md="6">
              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      
    </v-dialog>
    <v-dialog v-model="tipModerator" max-width="600" hide-footer centered>
      <v-card>
        <v-card-title>
          {{ moderator.moderator_name }}
        </v-card-title>
        <v-card-text>
          {{ moderator.moderator_sub_title }}
        </v-card-text>
        <v-card-actions>
          <v-btn small block color="success" :href="moderator.moderator_url">
visit          </v-btn>

        </v-card-actions>
      </v-card>
    
    </v-dialog>
   <v-row>
<v-col cols="12">
  <v-card
      text-variant="primary"
      title-variant="primary"
      @click="tipModerator = true"
      style="max-height: 5.4rem; min-height: 5.4rem; max-width:18rem; border-radius: 3px; "
      id="moderator"
      class="m-0 mt-2 p-0 d-flex moderator card-menu-darken"
    >
      <v-card-text>
        <v-row align-v="center" no-gutters>
          <v-col cols="4" class="text-xs ">
            <v-avatar
              style=" box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
              tile
              size="54"
            >
            <v-img contain :src="moderator.moderator_img_src">

            </v-img>
          </v-avatar>
          </v-col>
          <v-col cols="8" align-v="center" class="text-xs my-auto">
            <p class="caption font-weight-bold white--text">
            <span class="white--text"> {{ moderator.moderator_name }}</span> 
           
          
          </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
</v-col>
   </v-row>
 
    
  </div>
</template>
  <script>
import PaypalView from '../../WalletModerator/PaypalView'
import store from '@/store'
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
  name: 'debate-moderator',
  components: {
    PaypalView,
  },
  props: {
    moderator: Object,
  },
  data() {
    return {
      rates: {
        rate_1: 0,
        rate_2: 0,
        rate_3: 0,
        rate_4: 0,
        rate_5: 0
      },
      total_rate:0,
            ratingModerator: false,
      tipModerator: false
    }
  },
  computed: {
    moderator_total_donation() {
      return store.state.debates.active_debate.donations.moderator
    },
    showModalModeratorDonate() {
      return store.state.ledger_auth.moderator.donation
    },
    showModalModeratorRate: {
      // getter
      get: function() {

        return store.state.ledger_auth.moderator.rate
      },
      // setter
      set: function(newValue) {
        this.showModalModeratorRate = newValue
      }
    },
    is_signed() {
      return this.$cookies.get('is_signed_in')
    },

  },
  methods: {
    goTo() {
      window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=https%3A%2F%2Fwww.axb.ardkon.com%2Fauth&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress+w_member_social&client_id=78dmbk81tskav0"
      console.log('hii')
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'tip-moderator', '#btnShow')
    }, showRate() {

      this.$root.$emit('bv::show::modal', 'rate-moderator', '#btnShow')

    },
  }

}
</script>
  <style>
.card-menu-darken{
  background-color: rgba(69,69,69,0.81)
}
.button-radius {
  border-radius: 9px !important;
}
.activity-card {
  max-height: 21vh;
  max-height: 21vh;
}
.moderator-img {
  max-height: 12vh;
  max-width: 12vw;
}

.text-bold {
  font-weight: 600;
}
.moderator-text {
  max-height: 2.1rem;
  overflow: hidden;
}

.card-menu-dark-mod {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #white!important;
  max-width: 20 vw;
  border-radius:3px !important;
}
.card-menu-white {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.63);
  max-width: 20 vw;
  border-radius: 3px !important;
}
@media only screen and (max-width: 420px) {
  .story-card .card-img-left {
    width: 100%;
    height: 100%;
    max-height: 15rem;
    min-height: 12rem;
    max-width: 6.3rem;
    min-width: 6.3rem;
    object-fit: cover;
  }
  .story-card-text {
    max-height: 6.3rem;
    min-height: 6.3rem;
    overflow: hidden;
  }
}
</style>
  