<template>

  <v-col class="p-0 text-primary" >
<v-row>
<v-col>
  <hr class="mx-2 my-0 mv-2" style="opacity:0.12; position:relative; top:-6px;">
               <v-row no-gutters class="mx-3">
                
                  <v-col md="10" class="  text-right text-xs ">
                    
                    <h5 class="text-success"> {{ debate_comment.first_name }} {{ debate_comment.last_name }}   </h5>

                                    <p class="caption "><strong> {{ debate_comment.chat_content }}</strong></p>


                                    </v-col>
                                    <v-col cols="2" class="text-right pl-3">
                                      <v-avatar  :src="debate_comment.profile_picture"  ></v-avatar> 
                                     </v-col>

               </v-row>
               
</v-col>
</v-row>
  </v-col>
</template>
<script>

export default {
    name: 'debate-comment-personal',
    components: {

    },
    props: {
      debate_comment: Object,
    },
    data() {
      return {

      }
      },
      methods: {

      }

  }
</script>
<style>

</style>
