<template>

   

<div>
  
  <v-card
      border-variant="primary"
      style="max-height: 4.2rem; min-height:4.2rem; width:100%;  z-index: 201;"
      
      id="topic"
      class="m-0 p-0 d-flex topic card-menu-greee"
    >
    <v-card-text>
      <v-row no-gutters justify="center">
        
      
        <v-col cols="12">
          <span class="text-sm info--text text-center">
            <span style=" font-size: 10px; line-height:10px;"  class="font-weight-bold">{{ topic.topic_title }}</span>
          </span>
        </v-col>
        
      </v-row>
    </v-card-text>
 
    </v-card>
 <v-dialog max-width="900" v-model="vote_dialog">
  <v-card>
    <v-card-title >{{topic.topic_title}}</v-card-title>
    <v-card-text>
      <span v-html="topic.topic_small_description"></span>

    </v-card-text>
  </v-card>
 </v-dialog>
</div>
</template>
<script>
export default {
  name: 'debate-topic-started',
  components: {

  },
  props: {
    topic: Object,
  },
  data() {
    return {
        style1:{
            width: '54%',
        },
        vote_dialog: false
    }
    },
    computed:{
    
    },
    methods: {
      showTopic() {
    this.$refs[this.topic.topic_id].show()
  },
    showModal() {
         this.$root.$emit('bv::show::modal', this.topic.topic_id, '#imgShow')
       },
    }

}
</script>
<style >
.card-menu-greee{
  background-color:rgba(226,229,232,.63)!important;
}
.activity-card{
max-height: 21vh;
max-height: 21vh;
}
.topic-img{
max-height: 12vh;
max-width: 12vw;
}

.text-bold{
font-weight:600;

}
.topic-text{
max-height: 2.1rem; overflow: hidden;
}
.topic{
border:0
}
.topic .card-img-left {
           width: 100%;
           height: 100%;
           max-height: 7.2rem;
           min-height: 7.2rem;
           max-width: 6rem;
           min-width: 6rem;
           object-fit: cover;
           border-top-left-radius: 3px;
           border-bottom-left-radius: 3px;
       }
.card-menu-dark{
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
background-color:rgba(0, 0, 0, 0.63);
max-width: 20 vw;
border-radius: 3px!important;

}
.card-menu-white-vote{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.63);
  border-radius: 3px !important;
}
.card-menu-white-vote:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(19,47,62, 0.8);
  border-radius: 3px !important;
}
.voted{
border-style: solid;
border: 9px!important;
border-color: #132F3E;
}
.card-menu-variant{
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
background-image:linear-gradient(to right, #132F3E , #1A4B35);
max-width: 20 vw;
border-radius: 3px!important;

}
.card-menu-transparent{
background-color: transparent;
/* max-width: 9vw; */
border-radius: 3px!important;
}

@media only screen and (max-width: 420px) {
.story-card .card-img-left {
  width: 100%;
  height: 100%;
  max-height: 15rem;
  min-height: 12rem;
  max-width: 6.3rem;
  min-width: 6.3rem;
  object-fit: cover;
}
.story-card-text{
max-height: 6.3rem; min-height: 6.3rem; overflow:hidden
}
}
</style>
