<template>
  <v-col class="p-0 text-primary">
    <v-row>
      <v-col>
        <v-row no-gutters>
          <v-col cols="10" class="text-xs my-auto">
            <v-text-field :rules="rules" hide-details="auto" v-model="question_content"></v-text-field>
          </v-col>
          <v-col cols="2" class="text-xs px-1">
            <v-btn color="white" small class="p-2" fab rounded style="border-radius: 15px; ">
              <v-img
                @click="questionStart"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Icon-round-Question_mark.svg/1200px-Icon-round-Question_mark.svg.png"
                height="39"
                width="39"
              ></v-img>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import store from '@/store'

export default {
  name: 'debate-question-input',
  components: {

  },
  props: {
  },
  data() {
    return {
      dismissCountDown: 0,
      showDismissibleAlert: false,
      question_content: '',
      message: '',
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],

    }
  },
  methods: {
    scrollToEnd1: function() {
      var content = this.$refs.questions;
      content.scrollTop = content.scrollHeight
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    questionStart() {

      if (this.question_content != '') {

        if (this.is_signed === 'true') {
          var user_question = this.question_content


          store.dispatch('debates/setMyQuestion',
            {              
debate_id: store.state.debates.active_debate.debate_id,

              question_content: user_question            
})

          this.question_content = ''

        } else {

          this.message = 'please register'

        }

      }
    }

  },
  computed: {

    is_signed() {
      return this.$cookies.get('is_signed_in')
    },


  },

}
</script>
<style>
</style>
