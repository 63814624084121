<template>
  <div class="main-debate">
    
    <v-container fill-height fluid v-if="is_signed_in === true">
      <v-row justify="center">
        <div class="hidden-row"></div>
        <div class="hidden-row"></div>
        <div class="hidden-row"></div>
        <div class="hidden-row"></div>
    
       

       

       

        <v-col
        
          cols="12"
          md="6"
        >
          <div>
           <request-debate-start></request-debate-start>
          </div>
        </v-col>

      

        

        

      </v-row>
    </v-container>
  </div>
</template>
<script>
import RequestDebateStart from './axb/RequestDebate/RequestDebateStart.vue'
// Charts

// Components



import store from '../store'

export default {
  components: {
    RequestDebateStart
  },
  store,

  data() {
    return {

      message_error: '',
      success_error: '',
      sharedState: store.state,
      is_signed_in: true,


    }
  },
methods:{

 
},

   async created() {

    // Need if statements depending on the sections that are existing this way we would not have errors








  },
  computed: {

   
  },







}
</script>
<style scoped>
.hidden-row-3 {
  position: relative;
  height: 54px !important;
  width: 100%;
}
.hidden-row-5 {
  height: 15px !important;
}
.hidden-row-5 .action {
  height: 12px !important;
}
.hidden-row-4 {
  position: relative;
  height: 45px !important;
  width: 100%;
}
.text-bold {
  font-weight: 600;
}
.right-sidebar {
  right: 0;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-pills {
}

@media only screen and (max-width: 981px) {
  .right-sidebar {
    position: relative;
    height: auto;
  }
}

.main-debate {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  background-color: #F9F9F9;
  overflow-x: hidden;
  overflow-y: scroll;
}

</style>
