<template>

<v-container fill-height fluid>
      <v-row align="center"
      justify="center">
      <div class="hiiden-row"></div>
        <v-col md="8">
          <debate-general-main v-bind:debate_general="debate_general"></debate-general-main>
          <debate-candidates></debate-candidates>

        </v-col>
        <v-col md="4">
          <debate-comments></debate-comments>
<debate-questions></debate-questions>
        </v-col>
      </v-row>
        
 
  
    </v-container>
  </template>
  <script>
  import DebateGeneralMain from './RoomInfo/DebateGeneralMain.vue'
  import DebateComments from './DebateComments/DebateComments'
       import DebateQuestions from './DebateQuestions/DebateQuestions'
       import DebateCandidates from './DebateCandidates/DebateCandidates'

  import store from "@/store";
  export default {
      name: 'moderator-tool-box',
      components: {
        DebateGeneralMain,
        DebateComments,
        DebateQuestions,
        DebateCandidates
      },
      props: {
        
      },
      data() {
        return {
            form: {
              username: '',
              password: '',
              debate_name: this.$route.params.room_id
            },
            debate_id :  this.$route.params.room_id
        
        }
      },
      computed: {

debate_general(){
  var general = store.state.debates.active_debate.general
  general.candidate_a = this.debate_Candidate_A
  general.candidate_b = this.debate_Candidate_B
  return general
},
 debate_Candidate_A(){
  return store.state.debates.active_debate.candidate_A.candidate_name
},
 debate_Candidate_B(){
  return store.state.debates.active_debate.candidate_B.candidate_name
}
 },
      async mounted(){
        
    await store.dispatch('debates/getMainInfo', { debate_id: this.debate_id })
    await store.dispatch('debates/getTopics', { debate_id: this.debate_id })
    await store.dispatch('debates/getModerator', { debate_id: this.debate_id })
    await store.dispatch('debates/GetChatMessages', { debate_id: this.debate_id })
    await store.dispatch('debates/GetQuestions', { debate_id: this.debate_id })
    await store.dispatch('debates/getCandidates', { debate_id: this.debate_id })
    await store.dispatch('debates/getDonationTotalCandidateA', { debate_id: this.debate_id, candidate_id: this.candidateA.candidate_id  })
    await store.dispatch('debates/getDonationTotalCandidateB', { debate_id: this.debate_id, candidate_id: this.candidateB.candidate_id  })
    await store.dispatch('debates/getDonationTotalModerator', { debate_id: this.debate_id, moderator_id: this.moderator.moderator_id  })
    

      },
        methods: {
        
          login(){
            store.dispatch('debates/moderatorLogin', this.form)
          }
        }
  
    }
  </script>
  <style >
  .hidden-row{
    height:12vh;
  }
    .card-menu-white{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(255, 255, 255, 0.63);
    max-width: 20 vw;
    border-radius: 18px!important;
  
  }
  </style>
  