<template>
  
            <v-card style="max-width: 630px; ">
                <v-card-title><h4>Moderator Details</h4></v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="6">
                                <v-select label="education" :items="education_items"></v-select>
                            </v-col>  <v-col cols="12" sm="6" md="6">
                                <v-select label="Communication Skills" :items="items"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select label="Experience" :items="items_1"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select multiple chips label="official working level language" v-model="language" :items="languages_select"></v-select>
                            </v-col>
                            <v-col justify="center" cols="12"   md="6"
        >
                                <v-btn color="info" block> submit</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
   
</template>
<script>
export default {
    name: 'moderator-step-1',
    components: {

    },
    data() {
        return {
            items:['direct', 'passive'],
            education_items:['BA', 'MS', 'MBA', 'PHD' ],
            items_1:['direct', 'passive'],
            languages_select:['english', 'arabic', 'french','spanish','other' ],
            language:[]
        }
    }
}
</script>