<template>

  <v-col class="py-0 text-primary" >
<v-row class="py-0 text-primary">
<v-col class="py-2 ">
  <hr class="mx-2 my-0 " style="opacity:0.12; position:relative; top:-6px;">
               <v-row class="py-0">
                 <v-col cols="2">
                  <v-avatar size="36"  ><v-img :src="debate_question.profile_picture"></v-img></v-avatar>
                 </v-col>

                  <v-col cols="10" class=" px-3 ">
                    <h5 class="info--text "> {{ debate_question.first_name }} {{ debate_question.last_name }} </h5>

                                    <p class="py-0 caption "><strong> {{ debate_question.question_content }}</strong></p>


                                    </v-col>
               </v-row>
              

</v-col>
</v-row>
  </v-col>
</template>
<script>

export default {
    name: 'debate-question',
    components: {

    },
    props: {
      debate_question: Object,
    },
    data() {
      return {

      }
      },
      methods: {

      }

  }
</script>
<style>

</style>
