<template>
  <div class="main-debate">
    <v-container  >
      <v-row >
        <v-col cols="12">
          <div class="hidden-row-room"></div>

        </v-col>
        <v-col cols="12" md="6">
          <h3 class="info--text text-lowercase">Current Panels</h3>
<hr class="info--text my-2">
<v-card to="/panel/voicesLIBEIRUT" style="border-radius: 9px!important;" >
  <v-card-subtitle class="grey--text font-weight-bold">
    voices LI Beirut
  </v-card-subtitle>
</v-card>
        </v-col><v-col cols="12"  md="6">
          <h3 class="success--text text-lowercase">axb</h3>
          <hr class="success--text my-2">
         


        </v-col>
      </v-row>
    </v-container>
    
  </div>
</template>
<script>
// Charts

// Components

import axios from "axios";

import store from '../store'

export default {
  components: {
    
   
   
  },
  store,

  data() {
    return {

      message_error: '',
      success_error: '',
      param: this.$route.params.action_params,
      sharedState: store.state,
      is_signed_in: true,

      funds_allocation: store.state.getAction.active_action.action_funds_allocation,

    }
  },
methods:{

 async login(){
    var href;
      await axios.post('https://techard.net/linked_in?basics=axb', {}, {
                                           }).then((res) => {
                                             console.log(res)
                                            href = res.data.authorization_uri
                                            console.log(href)
                                             this.authorize(href)
                                           })
  },

  async authorize(href){
    axios.get(href).then((res1) => {
                                             console.log(res1)
                                            
                                           })
  }
},

   async created() {

    // Need if statements depending on the sections that are existing this way we would not have errors

    await store.dispatch('debates/rmDebates')
    await store.dispatch('debates/getDebates')












  },
  computed: {

   
    action() {

      return store.getters['getAction/getActiveAction'](this.param)

    },
    action_general() {
      return store.state.getAction.active_action.action_general
    },

  },







}
</script>
<style scoped>
.hidden-row-3 {
  position: relative;
  height: 54px !important;
  width: 100%;
}
.hidden-row-5 {
  height: 15px !important;
}
.hidden-row-5 .action {
  height: 12px !important;
}

.hidden-row-room {
  height: 63px;
}
.hidden-row-4 {
  position: relative;
  height: 45px !important;
  width: 100%;
}
.text-bold {
  font-weight: 600;
}
.right-sidebar {
  right: 0;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-pills {
}

@media only screen and (max-width: 981px) {
  .right-sidebar {
    position: relative;
    height: auto;
  }

.hidden-row-room {
  height: 81px;
}
}

.main-debate {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  background-color: #F9F9F9;
  overflow-x: hidden;
  overflow-y: scroll;
}

</style>
