<template>
    <div  class=" m-0 mr-4">

      
      <v-card class="card-menu-dark mt-6 mv-3 " v-if="sharedState.ledger_auth.chat_room.questions === true" >
  <v-card-text>
   <p class="text-primary text-xl my-1 font-weight-bold"><strong style="display:inline-block;
            border-bottom:1.5px solid black;
          
            padding-bottom:2px;">questions</strong><span style="font-weight: 222; opacity:0.69; cursor:pointer;" @click="setChat"> | chat</span> </p>

         <div id="questions" class="my-3 p-0 m-0 DebateComments" style="max-height: 15rem; overflow-y: scroll; overflow-x:hidden;">
            <debate-question :key="question.question_id" v-for="question in debate_questions" :debate_question="question"></debate-question>
            <debate-question-personal :key="question" v-for="question in debate_questions_personal" :debate_question="question"></debate-question-personal>
    </div>
       <debate-question-input></debate-question-input>
  </v-card-text>
  
      </v-card>
                   

    </div>
</template>

<script>

import DebateQuestion from './DebateQuestion.vue'
import DebateQuestionPersonal from './DebateQuestionPersonal.vue'
import DebateQuestionInput from './DebateQuestionInput.vue'
   import store from '@/store'

    export default {
     name: 'debate-questions',
     components:{
      DebateQuestion,
      DebateQuestionPersonal,
      DebateQuestionInput
     },
     data() {
           return {
              sharedState: store.state
           }
           },
        props: {
        },
        methods:{
           setChat(){
            store.dispatch('ledger_auth/setChat')
           },
           scrollToEnd1: function () {
            var content = this.$refs.questions;
            content.scrollTop = content.scrollHeight
        },
           scrollToEnd: function() {    	
      var container = this.$el.querySelector("#questions");
      container.scrollTop = container.scrollHeight;
    
    },
        },
        mounted(){
        this.scrollToEnd1()
        },
        computed:{
         debate_questions(){
            return store.state.debates.active_debate.questions
         } , 
         debate_questions_personal(){
            return store.state.debates.active_debate.my_questions
         }
        }
    };
</script>
<style >
.card-comment {

 box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
    background-color:rgba(	226,	229,	232, 0.63);
}
.DebateComments{
   min-height: 45vh;
}
.card-menu-dark{
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(0, 0, 0, 0.27)!important;
    max-width: 20 vw;
    border-radius: 18px!important;
}
</style>
