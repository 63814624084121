<template>
<div>
   
   <v-card-text>
    <v-row class="partners" no-gutters>
  
  <v-col v-for="partner in partners" :key="partner.partner_id" cols="12" class="my-auto" md="6">
      <partner :partner="partner"></partner>
  </v-col>
</v-row>
   </v-card-text>

</div>
</template>
<script>
import Partner from './Partner.vue'
export default {
    name: 'partners',
    components:{
        Partner,
        
    },
    data(){
        return {
            partners:[{
                partner_title: 'HeartB',
                partner_small_description: 'HeartB s mission is making social change easy, effective and accessible to everyone. We do this by empowering and connecting people online who want to contribute to social change, so they can share personal stories, support each other and discuss solutions. HeartB is a platform to structurally debate solutions for human rights issues and to bridge gaps by shedding lights on realities of everyday life.',
                partner_img_url: 'https://heartb.foundation/wp-content/uploads/2021/02/cropped-BLUE-GRADIENT-1-1-1536x303.png',
                partner_url: 'https://heartb.foundation/',
            },{
                partner_title: 'ardkon',
                partner_small_description: 'HeartB s mission is making social change easy, effective and accessible to everyone. We do this by empowering and connecting people online who want to contribute to social change, so they can share personal stories, support each other and discuss solutions. HeartB is a platform to structurally debate solutions for human rights issues and to bridge gaps by shedding lights on realities of everyday life.',
                partner_img_url: 'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1614070289684.png',
                partner_url: 'https://ardkon.com',
            }]
        }
    },
    computed: {
        
    }
}
</script>
<style>

</style>