<template>
   <div class="m-0 mr-4">
      <v-card
         class="card-menu-dark mt-6 mv-3 p--1"
         v-if="sharedState.ledger_auth.chat_room.chat === true"
      >
         <v-card-text>
            <p class="text-primary text-xl my-1 font-weight-bold">
               <strong
                  style="display:inline-block;
            border-bottom:1.5px solid black;
          
            padding-bottom:2px;"
               >chat</strong>
               <span
                  style="font-weight: 222; opacity:0.69; cursor:pointer;"
                  @click="setQuestions"
               >| questions</span>
            </p>

            <div
               id="chat"
               class="my-3 p-0 m-0 DebateComments"
               style="max-height: 18rem; overflow-y: scroll; overflow-x:hidden;"
            >
               <debate-comment
                  :key="comment.chat_id"
                  v-for="comment in Debate_comments"
                  :debate_comment="comment"
               ></debate-comment>
               <debate-comment-personal
                  :key="comment.chat_id"
                  v-for="comment in Debate_comments_personal"
                  :debate_comment="comment"
               ></debate-comment-personal>
            </div>
            <debate-comment-input></debate-comment-input>
         </v-card-text>
      </v-card>
   </div>
</template>

<script>
import DebateComment from './DebateComment.vue'
import DebateCommentPersonal from './DebateCommentPersonal.vue'
import DebateCommentInput from './DebateCommentInput.vue'
import store from '@/store'

export default {
   name: 'Debate-comments',
   components: {
      DebateComment,
      DebateCommentInput,
      DebateCommentPersonal
   },
   data() {
      return {
         sharedState: store.state
      }
   },
   props: {
   },
   methods: {
      setQuestions() {
         this.scrollToEnd()
         store.dispatch('ledger_auth/setQuestions')
      },
      scrollToEnd: function() {
         var container = this.$el.querySelector("#chat");
         container.scrollTop = container.scrollHeight;

      },
   },
   mounted() {

   },
   computed: {
      Debate_comments() {
         return store.state.debates.active_debate.chat
      },
      Debate_comments_personal() {
         return store.state.debates.active_debate.my_chat
      }
   }
};
</script>
<style >
.card-comment {
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
   background-color: rgba(226, 229, 232, 0.63);
}
.DebateComments {
   min-height: 45vh;
}
.card-menu-dark {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   background-color: rgba(0, 0, 0, 0.27) !important;
   max-width: 20 vw;
   border-radius: 18px !important;
}
</style>
