<template>
  <div class="main-debate" style="height:100vh">
    <v-container   >
      <v-row no-gutters
        style="height: 600px" >
        <v-col md="12"  align-self="center">
         
  <linked-in v-if="sharedState.ledger_auth.auth.linked_in === true"></linked-in>       
<choose-region v-if="sharedState.ledger_auth.auth.region === true"></choose-region>
<choose-type v-if="sharedState.ledger_auth.auth.type === true"></choose-type>
<choose-intrests v-if="sharedState.ledger_auth.auth.intrests === true"></choose-intrests>

        </v-col>
      </v-row>
    </v-container>
   
  </div>
</template>
<script>
import LinkedIn from './axb/Debate/SignUp/LinkedIn.vue'
// Charts

// Components


import ChooseRegion  from './axb/Debate/SignUp/ChooseRegion.vue'
import ChooseType  from './axb/Debate/SignUp/ChooseType.vue'
import ChooseIntrests  from './axb/Debate/SignUp/ChooseIntrests.vue'

import store from '../store'

export default {
  components: {
    LinkedIn,
    ChooseIntrests,
    ChooseType,
    ChooseRegion
   
  },
  store,

  data() {
    return {
      step:{
        linked_in:true,
        region: false, 
        type: false,
        intrests: false,
      },
      message_error: '',
      success_error: '',
      param: this.$route.params.action_params,
      sharedState: store.state,
      is_signed_in: true,

      funds_allocation: store.state.getAction.active_action.action_funds_allocation,

    }
  },
methods:{

},

    created() {

    // Need if statements depending on the sections that are existing this way we would not have errors

   
     




  },
  computed: {

   
    action() {

      return store.getters['getAction/getActiveAction'](this.param)

    },
    action_general() {
      return store.state.getAction.active_action.action_general
    },

  },







}
</script>
<style scoped>
.hidden-row-3 {
  position: relative;
  height: 54px !important;
  width: 100%;
}
.hidden-row-5 {
  height: 15px !important;
}
.hidden-row-5 .action {
  height: 12px !important;
}
.hidden-row-4 {
  position: relative;
  height: 45px !important;
  width: 100%;
}
.text-bold {
  font-weight: 600;
}
.right-sidebar {
  right: 0;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-pills {
}

@media only screen and (max-width: 981px) {
  .right-sidebar {
    position: relative;
    height: auto;
  }
}

.main-debate {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  background-color: #F9F9F9;
  overflow-x: hidden;
  overflow-y: scroll;
}

</style>
