<template>
<div class="intrnal-main-general"> 

<v-row style="position:relative;  " class="">


<v-col md="12" class="">
  
  <debate-general-main v-bind:debate_general="debate_general"></debate-general-main>










  <v-dialog max-width="420" v-model="sharedState.ledger_auth.chat_room.questions">
    <debate-questions></debate-questions>

  </v-dialog>


<br>




                    </v-col>



                                                  </v-row>




                    </div>
</template>
<script>

import store from '@/store'

import DebateGeneralMain from './DebateGeneralMain.vue'
import DebateQuestions  from './DebateQuestions/DebateQuestions'
export default {
    name: 'debate-view',
    components: {
      DebateGeneralMain,
      DebateQuestions
     },
    props: {
    },

    data() {
      return {
         

       sharedState: store.state,
       

      }
    },
    computed: {

   debate_general(){
     var general = store.state.debates.active_debate.general
    
     return general
   },
    
    }


  }
</script>
<style>
.internal-main-general{
 height: 90vh;
}
.fund-mobile{
display:none;
}
.main-action-hr{
  margin:6px!important;
  padding:0px;
}
hr{
border-top: 1px solid;
margin: 6px;
border-color: black;
}
.hidden-row-mm{
  height: 6vh;
}
.sections{
min-width: 100%;

max-height: 21vh;


overflow-x: hidden;
overflow-y: scroll;

}
.cardss{
max-height: 39vh;
overflow: hidden;
}
.bcard-height{
max-height:24rem;
height:24rem;

}
.general{
  max-height: 60vh;
  overflow-y: scroll;


}
@media (max-width: 978px) {
    
    body {
      padding:0;
    }

    .navbar screen and (max-width: 693px) {
  .hidden-ro-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
      margin-left: 0;
      margin-right: 0;
      margin-bottom:0;
    }
}@media (max-width: 720px) {
    
  .internal-main-general{
 height: auto;
}
}
@media onlyw-mm{
  height: 12vh;
}
.general{
  max-height: 100%;
  min-height: 100%;
}
.sections{
min-width: 100%;

max-height: 100%;
min-height: 100%;

overflow-x: scroll;
overflow-y: hidden;

}
}
@media only screen and (max-width: 989px) {
.fund-mobile{
display: block;
}
}
.hidden-row{
position:relative;
height: 18px;
width:100%;
}
</style>
