<template>
   
            <v-card style="max-width: 630px; " class="card-menu-white">
                <v-card-title><h4>Candidate Details</h4></v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-select label="Candidate Type" :items="items"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select multiple chips label="Office" v-model="office" :items="items_1"></v-select>
                                <v-text-field v-if="office ==='other'" label="add your office"></v-text-field>
                            </v-col>
                               <v-col cols="12" sm="6" md="6">
                                <v-select multiple chips label="official working level language" v-model="language" :items="languages_select"></v-select>
                            </v-col>
                            <v-col self-align="end" cols="12"   md="6"
        offset-md="3">
                                <v-btn class="my-auto" block color="info"> submit</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        
</template>
<script>
export default {
    name: 'candidate-step-1',
    components: {

    },
    data() {
        return {
            items:['municipality', 'parliment', 'syndicate','university'],
            items_1:['lb', 'passive', 'other'],
            office: [],
            languages_select:['english', 'arabic', 'french','spanish','other' ],
            language:[]
        }
    }
}
</script>