<template>

  <v-col class="py-0 text-primary" >
<v-row class="py-0 pt-1 ">
<v-col class="py-1">
               <v-row no-gutters>
                 

                  <v-col cols="12" class="info--text   ">
                    <span  class="caption ">  {{ debate_comment.first_name }} {{ debate_comment.last_name }}: </span>

                                    <span  class="caption "><strong > {{ debate_comment.chat_content }}</strong></span><br>


                                    </v-col>
               </v-row>
              

</v-col>
</v-row>
  </v-col>
</template>
<script>

export default {
    name: 'debate-comment',
    components: {

    },
    props: {
      debate_comment: Object,
    },
    data() {
      return {

      }
      },
      methods: {

      }

  }
</script>
<style>

</style>
