<template>
  <div class="main-debate">
    
    <v-container fluid>
      <v-row class="d-flex my-0">
        <v-col class="text-left" md="2" cold="12">
          <v-img
            contain
            style="display:block; cursor: pointer; z-index:202;"
            src="https://media.discordapp.net/attachments/835173834855284787/835478398260346890/logoled-1.png?width=648&height=463"
            class="logo-axb-1 text-right"
            @click="toHome"
          ></v-img>
        </v-col>
        <v-col md="2"></v-col>
        <v-col cols="12" md="4">
          <v-tabs v-model="tab" background-color="transparent" color="basil" class="my-0" grow>
            <v-tab
              v-for="item in items_1"
              :key="item"
              @click="changeDebate(item.debate_param)"
              class="font-weight-bold"
            >{{ item.name }}</v-tab>
          </v-tabs>
        </v-col>
        <v-col md="4" class="text-right" cols="12">
          <v-btn @click="setPartners = true" fab class="ml-auto mx-2" style="position: relative;">
            <v-icon class="primary--text">mdi-handshake-outline</v-icon>
          </v-btn>
          <v-btn
            @click="setAuthPannel"
            v-if="is_signed != 'true'"
            fab
            class="ml-auto"
            style="position: relative;"
          >
            <v-icon class="primary--text">mdi-account</v-icon>
          </v-btn>
          <v-dialog max-width="900" v-model="setPartners">
            <v-card color="primary">
              <v-card-title class="white--text font-weight-bold">partners</v-card-title>
              <Partners></Partners>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="8">
          <div>
            <debate-view></debate-view>
          </div>
        </v-col>

        <v-col cols="12" class md="4">
          <debate-view-side-bar ref="radio"></debate-view-side-bar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// Charts

// Components

import axios from "axios";

import DebateView from './axb/Debate/DebateView.vue';
import DebateViewSideBar from './axb/Debate/DebateViewSideBar.vue';
import Partners from './axb/Partners/Partners'
import store from '../store'
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
export default {
  components: {



    DebateView,
    DebateViewSideBar,
    Partners
  },
  store,

  data() {
    return {

      message_error: '',
      success_error: '',
      sharedState: store.state,
      is_signed_in: true,
      panel_name: this.$route.params.panel_name,
      setPartners: false,

      tab: 0,
     
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
      easings: Object.keys(easings),

    }
  },
  methods: {
    setAuthPannel() {
      store.dispatch('ledger_auth/setAuthPannel')
      this.$vuetify.goTo("radio", this.options)
    },
    toHome() {
      this.$router.push({ name: 'debate_main' })
    },
    async changeDebate(item) {
      //await store.dispatch('debates/removeDebateActive', item)
      await store.dispatch('debates/setDebateActive', item)

      await store.dispatch('debates/getMainInfo', { debate_id: this.debate.debate_id })
      await store.dispatch('debates/getTopics', { debate_id: this.debate.debate_id })
      await store.dispatch('debates/getGuests', { debate_id: this.debate.debate_id })

      await store.dispatch('debates/getModerator', { debate_id: this.debate.debate_id })
      await store.dispatch('debates/GetChatMessages', { debate_id: this.debate.debate_id })
      await store.dispatch('debates/GetQuestions', { debate_id: this.debate.debate_id })
      await store.dispatch('debates/getCandidates', { debate_id: this.debate.debate_id })
      await store.dispatch('debates/GetChatMessagesCons', { debate_id: this.debate.debate_id })

    },
    async login() {
      var href;
      await axios.post('https://techard.net/linked_in?basics=axb', {}, {
      }).then((res) => {
        console.log(res)
        href = res.data.authorization_uri
        console.log(href)
        this.authorize(href)
      })
    },

    async authorize(href) {
      axios.get(href).then((res1) => {
        console.log(res1)

      })
    }
  },



  async created() {

    document.title = 'VOICES LI BEIRUT'
    // Need if statements depending on the sections that are existing this way we would not have errors
    await store.dispatch('debates/rmDebates')

    await store.dispatch('debates/getDebates')



    await store.dispatch('debates/setDebateActive', this.debate_param)

    await store.dispatch('debates/getMainInfo', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/getTopics', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/getGuests', { debate_id: this.debate.debate_id })

    await store.dispatch('debates/getModerator', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/GetChatMessages', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/GetChatMessagesCons', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/GetQuestions', { debate_id: this.debate.debate_id })
    await store.dispatch('debates/getCandidates', { debate_id: this.debate.debate_id })













  },
  computed: {

    items_1(){
      var items = []
      if(this.panel_name === 'voicesLIBEIRUT'){
       items =  [{
        name:'stage1',
        debate_param: 'stage1'
       }
        ,{
        name:'stage2',
        debate_param: 'stage2'
       }
        ,{
        name:'stage3',
        debate_param: 'stage3'
       }
       
      ]
      }
      else if(this.panel_name === 'e-talks'){
        items =  [{
        name:'stage1',
        debate_param: 'igg1'
       }
        ,{
        name:'stage2',
        debate_param: 'igg2'
       }
        ,{
        name:'stage3',
        debate_param: 'igg3'
       }]
      }else if(this.panel_name === 'igg'){
        items =  [{
        name:'stage1',
        debate_param: 'igg1'
       }
        ,{
        name:'stage2',
        debate_param: 'igg2'
       }
        ,{
        name:'stage3',
        debate_param: 'igg3'
       }]
      }else{
        items =  [{
        name:'stage1',
        debate_param: 'test1'
       }
        ,{
        name:'stage2',
        debate_param: 'test2'
       }
        ,{
        name:'stage3',
        debate_param: 'test3'
       }]
      }
     
      return items
    },
    debate_param(){
      if(this.panel_name === 'voicesLIBEIRUT'){
        return 'stage1'
      }else if(this.panel_name === 'e-talks'){
        return 'igg1'
      }else{
        return 'test1'
      }
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      }
    },
    is_signed() {
      return this.$cookies.get('is_signed_in')
    },
    moderator() {
      return store.state.debates.active_debate.moderator
    },

    debate() {

      return store.state.debates.active_debate

    },
    action_general() {
      return store.state.getAction.active_action.action_general
    },

  },







}
</script>
<style scoped>
.logo-axb-1 {
  height: 54px;
}
.hidden-row-3 {
  position: relative;
  height: 54px !important;
  width: 100%;
}
.hidden-row-5 {
  height: 15px !important;
}
.hidden-row-5 .action {
  height: 12px !important;
}
.hidden-row-4 {
  position: relative;
  height: 45px !important;
  width: 100%;
}
.text-bold {
  font-weight: 600;
}
.right-sidebar {
  right: 0;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-pills {
}

@media only screen and (max-width: 981px) {
  .right-sidebar {
    position: relative;
    height: auto;
  }
}
.main-debate {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;

  background-color: #f9f9f9;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media only screen and (max-width: 981px) {
  .main-debate {
   
    height: auto;
   
  }
}


</style>
