<template>
    <div>
        <v-card class="py-4 m-4 d-flex topic card-menu-white text-center">
            <v-card-text>
                <v-row class="py-4">
                <v-col sm="3" cols="6" v-for="n_type in types" :key="n_type.name">
                    <v-card
                        border-variant="primary"
                        class="d-flex topic m-1"
                        @click="goDark(n_type)"
                        :class="{
                            'card-menu-white-types': n_type.darkMode,
                            'card-menu-dark-types': !n_type.darkMode,
                        }"
                    >
                    <v-card-title>
                        <h3
                            class="py-2 pt-4 m-1 type-card-text"
                            :class="{
                                'dark-type-card-text': n_type.darkMode,
                                'white-type-card-text': !n_type.darkMode,
                            }"
                        >{{ n_type.name }}
                        
                    </h3>
                    </v-card-title>
                       
                    </v-card>
                </v-col>
                <v-col class="mt-3">
                    <p class="text-danger">{{ message }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="4"></v-col>
                <v-col sm="4">
                    <v-btn
                        block
                        class="text-center"
                        style="border-radius: 12px;"
                        color="success"
                        @click="next"
                    >next</v-btn>
                </v-col>
            </v-row>
            </v-card-text>
           
        </v-card>
    </div>
</template>
  <script>
import store from "@/store";

export default {
    name: 'choose-type',
    components: {

    },
    props: {
        topic: Object,
    },
    data() {
        return {
            darkMode: true,
            types: [{
                name: 'Municipality',
                darkMode: true,
                selected: false
            },
            {
                name: 'University',
                darkMode: true,
                selected: false
            },
            {
                name: 'Parliment',
                darkMode: true,
                selected: false
            },
            {
                name: 'Syndicate',
                darkMode: true,
                selected: false
            }],


            form: {
                types: [],

            },
            message: ''
        }
    },
    methods: {
        next() {

            this.form.types = []
            for (var i = 0; i < this.types.length; i++) {
                var b_type = this.types[i]
                console.log(b_type)
                if (b_type.selected === true) {
                    this.form.types.unshift(b_type.name)
                }
            }

            if (this.form.types.length === 0) {

                this.message = 'please choose at least 1 type'
            } else {

                store.dispatch('ledger_auth/setAuthLocInterests')
                store.dispatch('debates/setType', this.form)
                
            }

        },
        goDark(n_type) {
            n_type.darkMode = !n_type.darkMode;
            n_type.selected = !n_type.selected;
            if (n_type.selected === true) {
                this.form.types.unshift(n_type.name)
            }


        },

        showModal() {
            this.$root.$emit('bv::show::modal', this.topic.action_user.action_user_id, '#btnShow')
        },
    }

}
</script>
  <style >
.type-card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.11);
    background-color: rgba(255, 255, 255, 0.63) !important;
    height: 12vh;
    cursor: pointer;
}

.type-card:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.11);
    background-color: #40454c !important;
    color: white !important;
    cursor: pointer;
}
.type-card-text {
    height: 12vh;
    cursor: pointer;
}
.type-card-text:hover {
    color: white !important;
    cursor: pointer;
}
.white-type-card-text {
    color: white !important;
    cursor: pointer;
}
.activity-card {
    max-height: 21vh;
    max-height: 21vh;
}
.topic-img {
    max-height: 12vh;
    max-width: 12vw;
}

.text-bold {
    font-weight: 600;
}
.topic-text {
    max-height: 2.1rem;
    overflow: hidden;
}
.topic {
    border: 0;
}
.topic .card-img-left {
    width: 100%;
    height: 100%;
    max-height: 7.2rem;
    min-height: 7.2rem;
    max-width: 6rem;
    min-width: 6rem;
    object-fit: cover;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.card-menu-dark {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(0, 0, 0, 0.63);
    max-width: 20 vw;
    border-radius: 18px !important;
}
.card-menu-dark-types {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(19, 47, 62, 0.63)!important;
    max-width: 20 vw;
    border-radius: 18px !important;
    color: white;
    border-width: 3px;
}

.card-menu-white-types {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(255, 255, 255, 0.63);
    max-width: 20 vw;
    border-radius: 3px !important;
    color: white;
}
.card-menu-white-types:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(19, 47, 62, 0.63);
    max-width: 20 vw;
    border-radius: 3px !important;
    color: white;
}
.voted {
    border-style: solid;
    border: 9px !important;
    border-color: #132f3e;
}
.card-menu-variant {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image: linear-gradient(to right, #132f3e, #1a4b35);
    max-width: 20 vw;
    border-radius: 3px !important;
}
</style>
  