<template>
  <v-col class="p-0 text-primary">
    <v-row>
      <v-col>
        <v-row >
          <v-col cols="10" class="text-xs m-auto">
            
            <v-text-field
      :rules="rules"
      hide-details="auto"
      v-model="comment_content"
    ></v-text-field>
           
          </v-col>
          <v-col cols="2" class="text-xs px-1">
            <v-btn rounded fab small color="white" class="px-2" >
              <v-img
                @click="commentStart"
                src="https://cdn.discordapp.com/attachments/835173834855284787/835517413763317791/send-1.png"
                height="21"
                width="21"
              ></v-img>
            </v-btn>
          </v-col>
        </v-row>
        {{message}}
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import store from '@/store'

export default {
  name: 'debate-comment-input',
  components: {

  },
  props: {
  },
  data() {
    return {
      comment_content: '',
      comment_username: '',
      message:'',
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
    }
  },
  methods: {
    scrollToEnd: function() {    	
      var container = this.$el.querySelector("#chat");
      console.log(container)

//      container.scrollTop = container.scrollHeight;
    },
    commentStart() {

      if (this.comment_content === '') {
        this.message = 'write smthg'
      } else {
        if (this.is_signed === 'true') {
          var user_chat = this.comment_content
         
            store.dispatch('debates/setMyChat', {
              debate_id: store.state.debates.active_debate.debate_id,
             
              chat_content: user_chat          
})
this.comment_content = ''
         this.scrollToEnd()

        } else {
          this.message = 'please login'
        }

      }
    }

  },
  computed: {

    is_signed() {
      return this.$cookies.get('is_signed_in')
    },


  },

}
</script>
<style>
</style>
