<template>
  <v-card text-variant="primary" class="my-2" ref="content">
    
    <v-card-title>
      <h4>donation amount:</h4>
    </v-card-title>
    <v-card-text>
      <v-row id="donate">
      
      <v-col class="pl-4 text-bold" cols="12">
  <v-text-field
          label="donation amount"
          
          prefix="$"
          type="number" v-model="product.price"
        ></v-text-field>

 
</v-col>

     
    </v-row>

    <paypal  style="z-index:10000;"  :product="product">Donate</paypal>

    
    </v-card-text>
   
  </v-card>
</template>
<script>
import Paypal from './Paypal'



export default {
  name: 'paypal-view',

  components: {
    'paypal': Paypal,

  },
  data() {
    return {
      product: {
        price: 10,
        description: 'Donated to action'
      },
      amount: 10,
      param: this.$route.params.action_params,



    }
  },
  mounted() {

  },
  computed: {


  },
  methods: {


    
    close: function() {



    }
  }

}
</script>
