<template>
  <div>
    <v-dialog
     
      transition="dialog-bottom-transition"
      scrollable
      v-model="dialogDonation"
      max-width="63vw"
      
    >
      <v-card  tile class="mx-auto">
        <v-card-title>
          <h3>{{ candidate.candidate_name }}</h3>
        </v-card-title>
        <v-card-text>
          <v-row style="overflow:hidden;">
            <v-col cols="12" md="12">
              <p class="my-4 text-primary">{{ candidate.candidate_sub_title }}</p>
            </v-col>
            <v-col class="my-auto py-0" cols="12" md="6" >
             <v-card class="text-lowercase">
              <v-card-title>
  <h4>Terms & Condiditions:</h4>

</v-card-title>
               <v-card-text>
               
              <v-checkbox  size="9px" class="shrink caption size-small"  >

                <template v-slot:label>
    <span >Program R&D</span>
  </template>
              </v-checkbox>
              <v-checkbox class="my-0 py-0 shrink" label="Campaign Actions">                <template v-slot:label>
    <span >Campaign Actions</span>
  </template></v-checkbox>
              <v-checkbox class="my-0 py-0 shrink" label="advertising & Marketing">

                <template v-slot:label>
    <span >advertising & Marketing</span>
  </template>
              </v-checkbox>
              <v-checkbox class="my-0 py-0 shrink" small label="Campaign Roadshow">

                <template v-slot:label>
    <span >Campaign Roadshow</span>
  </template>
              </v-checkbox>
              <br>
              <p class="caption">2.90% + 30&cent; will be included as Paypal fees in the US. <br>
    5.40% + 30&cent; will be included as Paypal fees internationally.
   </p>
               </v-card-text>
             </v-card>
             
            </v-col>
            <v-col class="my-0 py-0" md="6">
              <paypal-view v-if="is_signed === 'true'"></paypal-view>
              <v-row>
                <v-col cols="6">
                  <div v-if="is_signed === 'false'">
                    <p style="font-weight: 555;">resiter to continue donation</p>
                    <v-img
                      fluid
                      style="cursor: pointer;"
                      @click="goTo"
                      src="https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1619585229718.png"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6"></v-col>
            <v-col md="6">
              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ratingCandidate">
      <v-card>
        <v-card-title>
        <h4>{{candidate.candidate_name}}</h4>  
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_1"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_1 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_1"
              ></v-slider>
              <h4 class="info--text text-center"> rate 1</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_2"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_2 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_2"
              ></v-slider>
              <h4 class="info--text text-center"> rate 2</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_3"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_3 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_3"
              ></v-slider>
              <h4 class="info--text text-center"> rate 3</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_4"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_4 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_4"
              ></v-slider>
              <h4 class="info--text text-center"> rate 4</h4>
            </v-col>
            <v-col class="my-3 mx-auto" md="2">
              <div class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="rates.rate_5"
                  color="success"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ rates.rate_5 }}</span>
                </v-progress-circular>
              </div>

              <v-slider
                hint="Im a hint"
                max="100"
                min="0"
                reverse
                color="success"
                v-model="rates.rate_5"
              ></v-slider>
              <h4 class="info--text text-center"> rate 5</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="total_rate"
                  color="info"
                  style="transform: scaleX(-1);"
                >
                  <span style="transform: scaleX(-1);">{{ total_rate }}</span>
                </v-progress-circular>
               <h4 class="info--text text-center"> total rate</h4><br>
               <v-btn color="info" @click="ratingCandidate = false" class="text-lowercase button-radius font-weight-bold">submit</v-btn>
            </v-col>
             
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog max-width="600" v-model="score">
      <v-card>
        <v-card-title>
        <h4>{{candidate.candidate_name}}</h4>  
        </v-card-title>
        <v-card-text>
   <span v-html="candidate.candidate_url"></span>
        </v-card-text>
        <v-card-actions>

        <v-btn :href="candidate.candidate_sub_title" small color="primary" block target="_blank">visit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <v-card
      color="rgba(255,255,255,0.63)"
      text-variant="primary"
      title-variant="primary"
      @click="score= true"
      border-variant="primary"
      style="max-height: 5.1rem; min-height:5.1rem;min-width: 12rem;"
      class="m-0 my-1 p-0 d-flex card-menu-white-12"
    >
      <v-card-text>
        <v-row align-v="center" class="d-flex" no-gutters>
          <v-col cols="6" class="text-xs ">
            <v-avatar
            tile
              size="54"
              style=" box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
            >
              <v-img contain :src="candidate.candidate_img_src"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="6" class="my-auto">
            <p class="caption font-weight-bold">
           <span class="info--text">  {{ candidate.candidate_name }}</span>  
              <br />
            </p>
            
          
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
   
    
 
  </div>
</template>
  <script>
import PaypalView from '../../../Wallet1/PaypalView'
import store from '@/store'
export default {
  name: 'debate-candidate',
  components: {
    PaypalView,

  },
  props: {
    candidate: Object,
    getMax: Number

  },
  data() {
    return {
      dialogDonation: false,
      ratingCandidate: false,
      score:false,
      rates: {
        rate_1: 0,
        rate_2: 0,
        rate_3: 0,
        rate_4: 0,
        rate_5: 0,
        
      }
    }
  },
  computed: {

    total_rate(){
      return (this.rates.rate_1 +  this.rates.rate_2 +  this.rates.rate_3 +  this.rates.rate_4 +  this.rates.rate_5) / 5
    },
    candidate_A_total_donation() {
      return store.state.debates.active_debate.donations.candidate_A
    }, candidate_B_total_donation() {
      return store.state.debates.active_debate.donations.candidate_B
    },
    candidateAModalDonation() {
      return store.state.ledger_auth.candidate.candodate_a_donation
    }, candidateBModalDonation() {
      return store.state.ledger_auth.candidate.candodate_b_donation
    },
    is_signed() {
      return this.$cookies.get('is_signed_in')
    },
    topics(){
            return store.state.debates.active_debate.topics.sort((a, b) => a.topic_order - b.topic_order )
          }

  },
  methods: {
    goTo() {
      window.location.href = "https://api.linkedin.com/oauth/v2/authorization?redirect_uri=https%3A%2F%2Fwww.axb.ardkon.com%2Fauth&response_type=code&state=bitchplease&grant_type=authorization_code&scope=r_liteprofile+r_emailaddress+w_member_social&client_id=78dmbk81tskav0"
      console.log('hii')
    },
    setCandidateActive() {
      store.dispatch('debates/setActiveCandidate', this.candidate)
      this.$root.$emit('bv::show::modal', this.candidate.candidate_id, '#btnShow')


    },
    showModal() {
      this.$root.$emit('bv::show::modal', this.candidate.action_user.action_user_id, '#btnShow')
    },
  }

}
</script>
  <style>
.button-radius {
  border-radius: 9px !important;
}
.activity-card {
  max-height: 21vh;
  max-height: 21vh;
}
.candidate-img {
  max-height: 12vh;
  max-width: 12vw;
}

.text-bold {
  font-weight: 600;
}
.candidate-text {
  max-height: 2.1rem;
  overflow: hidden;
}

.candidate {
  border: 0;



}
.candidate .card-img-left {
  width: 100%;
  height: 100%;
  max-height: 7.2rem;
  min-height: 7.2rem;
  max-width: 6rem;
  min-width: 6rem;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 0;
}
.card-menu-dark {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(0, 0, 0, 0.63);
  max-width: 20 vw;
  border-radius: 3px !important;
}
.card-menu-darker {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(40, 44, 51, 0.81);
  max-width: 20 vw;
  border-radius: 3px !important;
}
.card-menu-white-12 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.63);
  border-radius: 3px !important;
}
@media only screen and (max-width: 420px) {
  .story-card .card-img-left {
    width: 100%;
    height: 100%;
    max-height: 15rem;
    min-height: 12rem;
    max-width: 6.3rem;
    min-width: 6.3rem;
    object-fit: cover;
  }
  .story-card-text {
    max-height: 6.3rem;
    min-height: 6.3rem;
    overflow: hidden;
  }
}
.my-checkbox .v-label {
  font-size: 9px;
  line-height:12px!important;
} 

::v-deep .my-checkbox .v-label {
 font-size: 9px;
 line-height:12px!important;
}
</style>
  