<template>
    <div>
        <v-card ref="region" border-variant="primary" id="topic" class="p-0 d-flex topic card-transparent">
            <v-card-text>
            <v-row class="h-100">
                <v-col
                    sm="12"
                    class="shaddow-text"
                    style="position:relative;vertical-align: middle;top:25%"
                >
                    
               

<v-text-field
            v-model="form.email_address"
            hint="This field uses counter prop"
            label="email address"
          ></v-text-field>
          
          <v-text-field
            v-model="form.first_name"
            hint="This field uses counter prop"
            label="first name"
          ></v-text-field>
          
          <v-text-field
            v-model="form.last_name"
            hint="This field uses counter prop"
            label="last name"
          ></v-text-field>

                    
                        
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4" align-self="end">
                    <p class="text-danger text-center">{{ message }}</p>
                    <v-btn
                        block
                        class="text-center"
                        @click="next"
                        color="info"
                    >submit</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        </v-card>
    </div>
</template>
  <script>
import store from "@/store";
export default {
    name: 'choose-region',
    components: {

    },
    props: {
        topic: Object,
    },
    data() {
        return {
            date:false,
            options: [
                {
                    value: 'Aakkar', text: 'Aakkar'
                }, {
                    value: 'Baalbek-Hermel', text: 'Aakkar'
                }, {
                    value: 'Beqaa', text: 'Beqaa'
                }, {
                    value: 'Beyrouth', text: 'Beyrouth'
                }, {
                    value: 'Batroun', text: 'Batroun'
                }, {
                    value: 'Bcharreh', text: 'Bcharreh'
                }, {
                    value: 'Bent Jbeil', text: 'Bent Jbeil'
                }, {
                    value: 'Chouf', text: 'Chouf'
                }, {
                    value: 'Hasbaiya', text: 'Hasbaiya'
                }, {
                    value: 'Hermel', text: 'Hermel'
                }, {
                    value: 'Jbeil', text: 'Jbeil'
                }, {
                    value: 'Jezzine', text: 'Jezzine'
                }, {
                    value: 'Keseroune', text: 'Keseroune'
                }, {
                    value: 'Koura', text: 'Koura'
                }, {
                    value: 'Marjaayoun', text: 'Marjaayoun'
                }, {
                    value: 'Matn', text: 'Matn'
                }, {
                    value: 'Minniey-Danniyeh', text: 'Minniey-Danniyeh'
                }, {
                    value: 'Natabatiyeh', text: 'Natabatiyeh'
                }, {
                    value: 'Rachaya', text: 'Rachaya'
                }, {
                    value: 'Saida', text: 'Saida'
                }, {
                    value: 'Sour', text: 'Sour'
                }, {
                    value: 'Tripoli', text: 'Tripoli'
                }, {
                    value: 'Zahleh', text: 'Zahleh'
                }, {
                    value: 'Zgharta', text: 'Zgharta'
                }



            ],
            form: {
                region: '',
                email_address: '',
                first_name:'',
                last_name:''

            },
            message: ''
        }
    },
    computed: {
        debate(){
            return store.state.debates.active_debate
        }
    },

    methods: {
        next() {
           
            this.form.region = 'VOICESLIBEIRUT'
                store.dispatch('ledger_auth/setAuthSubmit')
                store.dispatch('debates/setRegion', this.form)
            

        },
        showModal() {
            this.$root.$emit('bv::show::modal', this.topic.action_user.action_user_id, '#btnShow')
        },
    }

}
</script>
<style>
.activity-card {
    max-height: 21vh;
    max-height: 21vh;
}
.topic-img {
    max-height: 12vh;
    max-width: 12vw;
}

.text-bold {
    font-weight: 600;
}
.topic-text {
    max-height: 2.1rem;
    overflow: hidden;
}
.topic {
    border: 0;
}
.topic .card-img-left {
    width: 100%;
    height: 100%;
    max-height: 7.2rem;
    min-height: 7.2rem;
    max-width: 6rem;
    min-width: 6rem;
    object-fit: cover;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.voted {
    border-style: solid;
    border: 9px !important;
    border-color: #132f3e;
}
.card-menu-variant {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image: linear-gradient(to right, #132f3e, #1a4b35);
    max-width: 20 vw;
    border-radius: 3px !important;
}
</style>
  