<template>
    <div>
<v-row class="px-4">
  
<v-col md="4">
  <debate-candidate  :candidate="candidate_A"  :getMax="getMax"></debate-candidate>
</v-col><v-col md="4" class="my-auto">
<debate-moderator :moderator="moderator" :getMax="getMax"></debate-moderator>
</v-col>
<v-col md="4">
  <debate-candidate  :candidate="candidate_B"></debate-candidate>
</v-col>
</v-row>
                        </div>
    </template>
    <script>
    
       import DebateModerator from '../DebateModerator/DebateModerator'
import store from '@/store'
    
    import DebateCandidate from './DebateCandidate/DebateCandidate.vue'
  
    export default {
        name: 'debate-candidates',
        components: {
    DebateModerator,
          DebateCandidate
         },
        props: {
        },
    
        data() {
          return {
             
            
           sharedState: store.state,
           candidates:[{
             candidate_id: 'The Price of Financial', 
             candidate_name: 'Simon Semaan',
             candidate_bio: 'Candidate A',
             candidate_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1613415795873.png',
            value_1: 69,
            value_2: 33
    
           },
           {
             candidate_id: 'The Price of Financial', 
             candidate_name: 'Simon Semaan',
             candidate_bio: 'Candidate B',
             candidate_img_src:'https://ard-platform.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1613415795873.png',
             value_1: 33,
            value_2: 69
    
           }]
    
          }
        },
        computed: {
          getMax(){
            return store.state.debates.active_debate.candidate_A.total_donation + store.state.debates.active_debate.candidate_B.total_donation 
          },
       candidate_A(){
         return store.state.debates.active_debate.candidate_A
       }, 
       candidate_B(){
         return store.state.debates.active_debate.candidate_B
       }, moderator(){
         return store.state.debates.active_debate.moderator
       }
        }
    
    
      }
    </script>
    <style>
    
    </style>
    