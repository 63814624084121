<template>

    <div>
       
        
        <v-card
        border-variant="primary"
        
                
        id="topic"
       class=" mx-5   p-0  d-flex topic card-menu-white " 
        >
        <v-card-text>

       
        <v-row     class="h-100">
           
              <v-col sm="12"   class=" shaddow-text " style="position:relative;vertical-align: middle;top:25%" >
                <v-form-group
                id="fieldset-3"
               
                label-for="input-1"

              >
              <h3 class="text-success">Choose Interests:</h3>
              
              
            
            <v-row>
              <v-col md="3" >
                    <div class="mx-2 my-2" :key="option.value" v-for="option in options">
                        <v-btn :outlined="!option.selected" block small @click="add(option)" color="success" >{{option.value}}</v-btn>
                    </div>
                    

                </v-col> <v-col md="3">
                    <div class="mx-2 my-2" :key="option_2.value" v-for="option_2 in options_2">
                        <v-btn :outlined="!option_2.selected" block small  @click="add(option_2)" color="success" >{{option_2.value}}</v-btn>
                    </div>

                </v-col><v-col md="3">
                    <div class="mx-2 my-2" :key="option_3.value" v-for="option_3 in options_3">
                        <v-btn :outlined="!option_3.selected" block small color="success"  @click="add(option_3)" >{{option_3.value}}</v-btn>
                    </div></v-col><v-col md="3">
                    <div class="mx-2 my-2" :key="option_4.value" v-for="option_4 in options_4">
                        <v-btn :outlined="!option_4.selected" block small color="success"  @click="add(option_4)" >{{option_4.value}}</v-btn>
                    </div>
                </v-col>
                
            
            <v-col><p class="text-danger">{{message}}</p></v-col>
            </v-row>
                     
                   
              </v-form-group> 
             
                             
                                </v-col>
<v-col cols="4"></v-col>
<v-col cols="4" align-self="end" >
    <v-btn block class="text-center" @click="submit" style="border-radius: 12px;" color="info" >submit</v-btn>

</v-col>
           </v-row>
          </v-card-text>
        </v-card>
 
  

</div>
  </template>
  <script>
    import store from "@/store";

  export default {
      name: 'choose-interests',
      components: {
  
      },
      props: {
        topic: Object,
      },
      data() {
        return {
            options_4:[ {
                    value: 'Social Justice', text: 'Social Justice', selected: false
                }, {
                    value: 'Racial Justice', text: 'Racial Justice', selected: false
                }, {
                    value: 'Cultural Justice', text: 'Cultural Justice', selected: false
                },{
                    value: 'Bureaucratic Liason', text: 'Bureaucratic Liason', selected: false
                }, {
                    value: 'Health', text: 'Health', selected: false
                }, {
                    value: 'Sports', text: 'Sports', selected: false
                },],

            options_3:[{
                    value: 'Social Justice', text: 'Social Justice', selected: false
                }, {
                    value: 'Racial Justice', text: 'Racial Justice', selected: false
                }, {
                    value: 'Cultural Justice', text: 'Cultural Justice', selected: false
                },{
                    value: 'Bureaucratic Liason', text: 'Bureaucratic Liason', selected: false
                }, {
                    value: 'Health', text: 'Health', selected: false
                }, {
                    value: 'Sports', text: 'Sports', selected: false
                }, ],
            options_2:[{
                    value: 'Social Justice', text: 'Social Justice', selected: false
                }, {
                    value: 'Racial Justice', text: 'Racial Justice', selected: false
                }, {
                    value: 'Cultural Justice', text: 'Cultural Justice', selected: false
                },{
                    value: 'Bureaucratic Liason', text: 'Bureaucratic Liason', selected: false
                }, {
                    value: 'Health', text: 'Health', selected: false
                }, {
                    value: 'Sports', text: 'Sports', selected: false
                },
            ],
            options:[{
                    value: 'Social Justice', text: 'Social Justice', selected: false
                }, {
                    value: 'Racial Justice', text: 'Racial Justice', selected: false
                }, {
                    value: 'Cultural Justice', text: 'Cultural Justice', selected: false
                },{
                    value: 'Bureaucratic Liason', text: 'Bureaucratic Liason', selected: false
                }, {
                    value: 'Health', text: 'Health', selected: false
                }, {
                    value: 'Sports', text: 'Sports', selected: false
                },

            ],
           form:{
               interests:[]
               
           },
           message:'',
        }
        },
        methods: {
          add(option){
                option.selected = !option.selected
                if(option.selected === true)
                this.form.interests.push(option.value)
            },
            submit(){
                if(this.form.interests.length === 0){
                    this.message="Please choose some interests"
                }else{
                    store.dispatch('ledger_auth/setAuthSubmit')
                    store.dispatch('debates/setInterest', this.form)
                }
                
            },
        showModal() {
             this.$root.$emit('bv::show::modal', this.topic.action_user.action_user_id, '#btnShow')
           },
        }
  
    }
  </script>
  <style >
  .activity-card{
    max-height: 21vh;
    max-height: 21vh;
  }
  .topic-img{
   max-height: 12vh;
    max-width: 12vw;
  }
  
  .text-bold{
    font-weight:600;
  
  }
  .topic-text{
    max-height: 2.1rem; overflow: hidden;
  }
  .topic{
  border:0
  }
  .topic .card-img-left {
               width: 100%;
               height: 100%;
               max-height: 7.2rem;
               min-height: 7.2rem;
               max-width: 6rem;
               min-width: 6rem;
               object-fit: cover;
               border-top-left-radius: 3px;
               border-bottom-left-radius: 3px;
           }
  .card-menu-dark{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(0, 0, 0, 0.63);
    max-width: 20 vw;
    border-radius: 3px!important;
  
  } .card-menu-white{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color:rgba(255, 255, 255, 0.63);
    max-width: 20 vw;
    border-radius: 3px!important;
  
  }
.voted{
    border-style: solid;
    border: 9px!important;
    border-color: #132F3E;
}
  .card-menu-variant{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image:linear-gradient(to right, #132F3E , #1A4B35);
    max-width: 20 vw;
    border-radius: 3px!important;
  }

  
  </style>
  