<template>

  <v-row>
    <v-col cols="12">
      <debate-topic-created     v-if="topic.topic_status === 'done'" :topic="topic"></debate-topic-created>
    <debate-topic-done    v-if="topic.topic_status ==='done'"  :topic="topic"></debate-topic-done>
    <debate-topic-voting  v-if="topic.topic_status === 'vote'" :topic="topic"></debate-topic-voting>
    <debate-topic-started v-if="topic.topic_status ==='created'"  :topic="topic"></debate-topic-started>
    <v-dialog centered :id="topic.topic_id" hide-footer style="z-index:201;">
      <v-row>
        <v-col>
          <span class="text-sm">
            <strong>{{ topic.topic_title }}</strong>
          </span>
          <br />
          <span class="mt-1 text-sm">{{ topic.topic_small_description }}</span>
          <br />
          <span class="mt-1 text-sm">{{ topic.topic_description }}</span>
        </v-col>
      </v-row>
    </v-dialog>
  </v-col>
  </v-row>

    


    
    

  
</template>
  <script>
import DebateTopicStarted from './DebateTopicStarted.vue'
import DebateTopicCreated from './DebateTopicCreated.vue'
import DebateTopicVoting from './DebateTopicVoting.vue'
import DebateTopicDone from './DebateTopicDone.vue'
export default {
  name: 'debate-topic',
  components: {
    DebateTopicStarted,
    DebateTopicCreated,
    DebateTopicDone,
    DebateTopicVoting
  },
  props: {
    topic: Object,
  },
  data() {
    return {
      style1: {
        width: '54%',
      }
    }
  },
  methods: {
    showTopic() {
      this.$refs[this.topic.topic_id].show()
    },
    showModal() {
      this.$root.$emit('bv::show::modal', this.topic.topic_id, '#imgShow')
    },
  }

}
</script>
  <style >
.activity-card {
  max-height: 21vh;
  max-height: 21vh;
}
.topic-img {
  max-height: 12vh;
  max-width: 12vw;
}

.text-bold {
  font-weight: 600;
}
.topic-text {
  max-height: 2.1rem;
  overflow: hidden;
}
.topic {
  border: 0;
}
.topic .card-img-left {
  width: 100%;
  height: 100%;
  max-height: 6rem;
  min-height: 6rem;
  max-width: 6rem;
  min-width: 6rem;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.card-menu-dark {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(0, 0, 0, 0.63);
  max-width: 20 vw;
  border-radius: 3px !important;
}
.voted {
  border-style: solid;
  border: 9px !important;
  border-color: #132f3e;
}
.card-menu-variant {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(to right, #132f3e, #1a4b35);
  max-width: 20 vw;
  border-radius: 3px !important;
}
.card-menu-transparent {
  background-color: transparent;
  /* max-width: 9vw; */
  border-radius: 3px !important;
}

@media only screen and (max-width: 420px) {
  .story-card .card-img-left {
    width: 100%;
    height: 100%;
    max-height: 15rem;
    min-height: 12rem;
    max-width: 6.3rem;
    min-width: 6.3rem;
    object-fit: cover;
  }
  .story-card-text {
    max-height: 6.3rem;
    min-height: 6.3rem;
    overflow: hidden;
  }
}
</style>
  