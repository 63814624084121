<template>
    <div>
        <v-row class="">
            <v-col class="py-1 my-0"  v-for="topic in topics" :key="topic.topic_name" cols="12">
              <debate-topic  :topic="topic"></debate-topic>
        
            </v-col>
        
          
        </v-row>
                        </div>
    </template>
    <script>
    
       import store from '@/store'
    
    import DebateTopic from './DebateTopic.vue'
    
    export default {
        name: 'debate-topics',
        components: {
            DebateTopic
         },
        props: {
        },
    
        data() {
          return {
             
    
           sharedState: store.state,
           
    
          }
        },
        computed: {
          topics(){
            return store.state.debates.active_debate.topics.sort((a, b) => a.topic_order - b.topic_order )
          }
       
        }
    
    
      }
    </script>
    <style>
    
    </style>
    