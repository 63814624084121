<template>

  <v-col class="p-0 my-0 text-primary" >
<v-row>
<v-col>
  <hr class="mx-2 my-0" style="opacity:0.12; position:relative; top:-6px;">
               <v-row  class="mx-3 my-0" >
                
                  <v-col md="10" class="  text-right text-xs ">
                    
                    <h5 class="success--text"> {{ debate_question.first_name }} {{ debate_question.last_name }}  </h5>

                                    <p class="text-xs caption "><strong> {{ debate_question.question_content }}</strong></p>


                                    </v-col>
                                    <v-col cols="2" class="text-right pl-3">
                                      <v-avatar   > <v-img :src="debate_question.profile_picture" ></v-img></v-avatar> 
                                     </v-col>

               </v-row>
               
</v-col>
</v-row>
  </v-col>
</template>
<script>

export default {
    name: 'debate-question-personal',
    components: {

    },
    props: {
      debate_question: Object,
    },
    data() {
      return {

      }
      },
      methods: {

      }

  }
</script>
<style>

</style>
