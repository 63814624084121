
<template>
<div>

    <div ref="paypal" ></div>

</div>
</template>
<script>

export default {
  name: 'paypal',

  components: {
  },
  props:{
    product: Object,
    amount: Object
  },
   data () {
        return {

             loaded: false,
                  paidFor: false,



        }
      },

      mounted: function() {


const script = document.createElement("script");
          script.src =
            "https://www.paypal.com/sdk/js?client-id=AecaRLz0PhiVFEu4BPSlkgCRCMCCm5-gyiqFoLJ2AMG4s08gKZ_i2UbnKCRWDSmhjPkEpaKkfBlqu3KG&currency=USD";
          script.addEventListener("load", this.setLoaded);
          document.body.appendChild(script);
        },
        methods: {
            setLoaded: function() {
              this.loaded = true;

              window.paypal
                .Buttons({


                  createOrder: (data, actions) => {
                  this.$bvModal.show('modal-1"')
                    return actions.order.create({
                      purchase_units: [
                        {
                          description: this.product.description,
                          amount: {
                            currency_code: "USD",
                            value: this.product.price
                          }
                        }
                      ]
                    });
                  },

                  onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    
                  
                    this.paidFor = true;
                         this.$store.dispatch('ledger_auth/closeCandidateDonationModal')
                         this.$store.dispatch('debates/setDonationCandidate',{order:order})
                        
                  },
                  onError: err => {
                    console.log(err);
                  }
                })
                .render(this.$refs.paypal);
            }
          }

}

</script>
