<template>
    <div>
        <v-row>
            <v-col sm="3" align-self="right" class="text-right">
                <v-avatar
                    size="99"
                    style=" box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
                >
                    <img :src="profile.profile_picture" alt="profile_picture" />
                </v-avatar>
            </v-col>
            <v-col class="my-auto">
                <p>
                    <span class="text-title success--text">{{ profile.full_name }}</span>
                </p>
                <p class="font-weight-bold">{{ profile.status }}</p>
                <span
                    class="font-weight-bold"
                    :key="i_type.value"
                    v-for="i_type in profile.types"
                >| {{ i_type.name }}</span>
                <span class="font-weight-bold">- {{ profile.region }}</span>
            </v-col>
        </v-row>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col>
                        <h2 class="info--text">interests:</h2>
                        <v-btn
                            outlined
                            plain
                            raised
                            rounded
                            color="info"
                            class="mx-1"
                            :key="interest.value"
                            v-for="interest in profile.interests"
                        >{{ interest.name }}</v-btn>
                    </v-col>
                </v-row>
                <br />
                <br />
                <v-row>
                    <v-col>
                        <h2 class="success--text">regions of interest:</h2>
                        <v-btn
                            outlined
                            plain
                            raised
                            rounded
                            color="success"
                            class="mx-1"
                            
                            :key="r_interest.value"
                            v-for="r_interest in profile.region_interests"
                        >{{ r_interest.name }}</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-card
                v-if="ledger.candidate === true"
                class="card-menu-dark"
                elevation="3"
  
  shaped
                    style=" min-height: 45vh;"
                >
                <v-card-text>
                    <h3 class="text-primary">
                        candidate donations
                        <span
                            style="font-weight:333; cursor: pointer"
                            @click="goModerator"
                        >| moderator donations</span>
                    </h3>
                    <v-timeline
            align-top
            dense
          >
                   
                        <v-timeline-item
                        :key="donation.transaction_id"
                        v-for="donation in profile.candidate_donations"
              color="success"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ donation.candidate_first_name }}</strong> @{{  donation.transaction_value }} {{ donation.transaction_currency }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
                </v-card-text>
                    
                </v-card>
                <v-card
                    v-if="ledger.moderator === true"
                    class="card-menu-dark"
                    elevation="3"
  
  shaped
                    style=" min-height: 45vh;"
                >
                <v-card-text>
                    <h3 class="text-primary">
                        moderator donations
                        <span
                            style="font-weight:333; cursor: pointer"
                            @click="goCandidate"
                        >| candidate donations</span>
                    </h3>

                    <v-timeline
            align-top
            dense
          >
                   
                        <v-timeline-item
                        :key="donation_m.transaction_id"
                        v-for="donation_m in profile.moderator_donations"
              color="success"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ donation_m.moderator_name }}</strong> {{ donation_m.transaction_value }} {{ donation_m.transaction_currency }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
                </v-card-text>
                    
                   
                  
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>

export default {
    name: 'profile-information',
    components: {

    },
    props: {
        profile: Object
    },
    data() {
        return {
            style1: {
                width: '54%',
            },
            ledger: {
                moderator: false,
                candidate: true
            }
        }
    },
    methods: {
        goModerator() {
            this.ledger.moderator = true
            this.ledger.candidate = false
        }, goCandidate() {
            this.ledger.moderator = false
            this.ledger.candidate = true
        },
        showModal() {
            this.$root.$emit('bv::show::modal', this.topic.action_user.action_user_id, '#btnShow')
        },
    }

}
</script>
<style scoped>
.text-title {
    font-weight: 639;
    font-size: 27px;
}
.card-menu-dark {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(0, 0, 0, 0.27) !important;
    max-width: 20 vw;
    border-radius: 3px !important;
}
</style>