<template>
  <div class="main-debate">
    
    <v-container style="height:100vh;" fluid v-if="is_signed_in === true">
      <v-row class="d-flex">
        <div class="hidden-row"></div>
        <div class="hidden-row"></div>
        
    
       

       

       <v-col md="3">

       </v-col>

        <v-col
        
          md="6"
        >
          <div>
           <ApplyCandidate v-if="sharedState.ledger_auth.apply_auth.type === true"></ApplyCandidate>
           <moderator-step-1 v-if="sharedState.ledger_auth.apply_auth.moderator === true"></moderator-step-1>
           <candidate-step-1 v-if="sharedState.ledger_auth.apply_auth.candidate === true"></candidate-step-1>
          </div>
        </v-col>

      

        

        

      </v-row>
    </v-container>
  </div>
</template>
<script>
import ApplyCandidate from './axb/Candidate/ApplyCandidate'
import ModeratorStep1 from './axb/Candidate/ModeratorStep1'
import CandidateStep1 from './axb/Candidate/CandidateStep1'

import store from '../store'

export default {
  components: {
    ApplyCandidate,
    ModeratorStep1,
    CandidateStep1
   
  },
  store,

  data() {
    return {

      message_error: '',
      success_error: '',
      param: this.$route.params.action_params,
      sharedState: store.state,
      is_signed_in: true,


    }
  },
methods:{

},

   async created() {

    // Need if statements depending on the sections that are existing this way we would not have errors










  },
  computed: {

   
  },







}
</script>
<style scoped>
.hidden-row {
  position: relative;
  height: 54px !important;
  width: 100%;
}
.hidden-row-5 {
  height: 15px !important;
}
.hidden-row-5 .action {
  height: 12px !important;
}
.hidden-row-4 {
  position: relative;
  height: 45px !important;
  width: 100%;
}
.text-bold {
  font-weight: 600;
}
.right-sidebar {
  right: 0;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-pills {
}

@media only screen and (max-width: 981px) {
  .right-sidebar {
    position: relative;
    height: auto;
  }
}

.main-debate {
  background: url("/Map.svg");
  background-repeat: no-repeat;
  background-position: top, left;
  background-size: cover;

  background-attachment: fixed;

  background-color: #F9F9F9;
  overflow-x: hidden;
  overflow-y: scroll;
}

</style>
