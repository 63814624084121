<template>
   <div class="">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
         <v-card
         v-bind="attrs"
          v-on="on"

         @click="scrollToEnd()"
         class="mt-0 "
         color="rgba(90,248,0,0.63)"
      >
      <v-btn
                  v-show="!hidden"
                  color="grey"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
                  @click="comment_1= true"
                >
                  <v-icon >mdi-plus</v-icon>
                </v-btn>
         <v-card-text>
            <span class="font-weight-bold white--text mx-auto"></span>

            <div
               id="chat"
               class=" p-0 m-0 "
               style="max-height: 6rem;min-height: 6rem;  overflow-y: scroll; overflow-x:hidden;"
            >
               <debate-comment
                  :key="comment.chat_id"
                  v-for="comment in Debate_comments"
                  :debate_comment="comment"
               ></debate-comment>
               <debate-comment-personal
                  :key="comment.chat_id"
                  v-for="comment in Debate_comments_personal"
                  :debate_comment="comment"
               ></debate-comment-personal>
            </div>
           
         </v-card-text>
      </v-card>
      </template>
      <span>pros</span>
    </v-tooltip>

         
      
      <v-dialog max-width="420" v-model="comment_1">
         <v-card>
            <v-card-title>add your comment</v-card-title>
            <debate-comment-input></debate-comment-input>
         </v-card>
         

      </v-dialog>
      
   </div>
</template>

<script>
import eb from '@/views/axb/eventbus/eventbus.js'
import DebateComment from './DebateComment.vue'
import DebateCommentPersonal from './DebateCommentPersonal.vue'
import DebateCommentInput from './DebateCommentInput.vue'
import store from '@/store'



export default {
   name: 'Debate-comments',
   components: {
      DebateComment,
      DebateCommentInput,
      DebateCommentPersonal
   },
   data() {
      return {
         sharedState: store.state,
         comment_1: false,
      }
   },
   props: {
   },
   methods: {
      send(){
         eb.send('inbound1', { name: 'tim', age: 587 });

      },
      setChat() {
          store.dispatch('ledger_auth/setChat')
         var container = this.$el.querySelector("#chat");

container.scrollTop = container.scrollHeight;   
      },
      scrollToEnd: function() {
         var container = this.$el.querySelector("#chat");
         container.scrollTop = container.scrollHeight;

      },
   },

    mounted() {
     

      eb.onopen = () => {

         console.log("opened")
         // set a handler to receive a message
         eb.registerHandler('user_comments_out', (error, message) => {
               console.log('received a message: ' + JSON.stringify(message.body));
               if(message.body.debate_id === this.active_debate_id){
                  store.dispatch('debates/addDynamicComment', message.body).then(mb=>{
                     this.scrollToEnd(mb)
                  })
                  

               }
            });

             eb.registerHandler('ticker.mystock', (error, message) => {
               console.log('received a message: ' + JSON.stringify(message.body));

                  

               
            });
      }
      
   },
   computed: {
      getComments(){
         var m ;
        
           return m
      },
 
    debate() {

      return store.getters['debates/getActiveDebate'](this.param)

    },
      active_debate_id(){
         return store.state.debates.active_debate.debate_id
      },
      Debate_comments() {
         return store.state.debates.active_debate.chat
      },
      Debate_comments_personal() {
         return store.state.debates.active_debate.my_chat
      }
   }
};
</script>
<style >
.card-comment {
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0 rgba(0, 0, 0, 0.12);
   background-color: rgba(226, 229, 232, 0.63);
}
.DebateComments {
   min-height: 36vh;
}
.card-menu-dark {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   background-color: rgba(0, 0, 0, 0.27) !important;
   max-width: 20 vw;
   border-radius: 9px !important;
}.card-menu-white {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   background-color: rgba(255, 255,255, 0.27) !important;
   max-width: 20 vw;
   border-radius:3px !important;
}
</style>
