<template>

  <v-col class="p-0 text-primary" >
<v-row>
<v-col>
  <hr class="mx-2 my-0 mv-2" style="opacity:0.12; position:relative; top:-6px;">
               <v-row no-gutters>
                 <v-col cols="2">
                  <v-avatar size="36"  > <v-img :src="debate_question.profile_picture"></v-img></v-avatar>
                 </v-col>

                  <v-col cols="9" class=" px-3 ">
                    <h5 class="info--text "> {{ debate_question.first_name }} {{ debate_question.last_name }} </h5>

                                    <p class="caption "><strong> {{ debate_question.question_content }}</strong></p>


                                    </v-col>
                                    <v-col cols="1">
                                      <v-btn @click="deleteQuestionModerator" x-small>-</v-btn>
                                    </v-col>
               </v-row>
              

</v-col>
</v-row>
  </v-col>
</template>
<script>
import store from '@/store'
export default {
    name: 'debate-question',
    components: {

    },
    props: {
      debate_question: Object,
    },
    data() {
      return {

      }
      },
      methods: {
        deleteQuestionModerator(){
          var form = {
            question_id: this.debate_question.question_id,
            debate_id: this.$route.params.room_id
            }
            
            store.dispatch('debates/deleteQuestionModerator', form)
          
        }
      }

  }
</script>
<style>

</style>
