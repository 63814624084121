<template>

  <v-container>
    <profile-information :profile="profile_info"></profile-information>
   
  </v-container>


  


  </template>
  <script>
 
import ProfileInformation  from './ProfileInformation.vue'
import store from '@/store'
  export default {
      name: 'debate-profile',
      components: {
        
        ProfileInformation
      },
      props: {
      },
      data() {
        return {
          
            style1:{
                width: '54%',
            }
        }
        },
        computed:{
          profile_info(){
            return store.state.debates.profile_info
          }
        },
        methods: {
        showModal() {
             this.$root.$emit('bv::show::modal', this.topic.action_user.action_user_id, '#btnShow')
           },
        }
  
    }
  </script>
  <style >
  .activity-card{
    max-height: 21vh;
    max-height: 21vh;
  }
  .topic-img{
   max-height: 12vh;
    max-width: 12vw;
  }
  
  .text-bold{
    font-weight:600;
  
  }
  .topic-text{
    max-height: 2.1rem; overflow: hidden;
  }
  .topic{
  border:0
  }
  .topic .card-img-left {
               width: 100%;
               height: 100%;
               max-height: 7.2rem;
               min-height: 7.2rem;
               max-width: 6rem;
               min-width: 6rem;
               object-fit: cover;
               border-top-left-radius: 3px;
               border-bottom-left-radius: 3px;
           }

.voted{
    border-style: solid;
    border: 9px!important;
    border-color: #132F3E;
}
  .card-menu-variant{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image:linear-gradient(to right, #132F3E , #1A4B35);
    max-width: 20 vw;
    border-radius: 18px!important;
  }

  
  @media only screen and (max-width: 420px) {
    .story-card .card-img-left {
      width: 100%;
      height: 100%;
      max-height: 15rem;
      min-height: 12rem;
      max-width: 6.3rem;
      min-width: 6.3rem;
      object-fit: cover;
  }
  .story-card-text{
    max-height: 6.3rem; min-height: 6.3rem; overflow:hidden
  }
  }
  </style>
  